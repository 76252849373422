import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { SxProps } from '@mui/material'
import { Media } from '../../service/media'
import NavContents from './NavContents'
import { Path } from 'routes'
import { getAuth } from 'firebase/auth'
import { useLocation } from 'react-router-dom'
import { auth } from 'service/api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUser } from 'service'
import { gColor } from 'styles/Theme'
interface Props {
  drawerWidth: number
}

export default function NavDrawer({ drawerWidth }: Props) {
  const location = useLocation()
  const queryClient = useQueryClient()
  const [fbUser, setfbUser] = useState(auth.currentUser)
  const [open, setOpen] = useState(false) // user controlled
  const [hide, setHide] = useState(false) // app controlled
  const [delayedIsLoading, setDelayedIsLoading] = useState(true) // prevents nav flicker for blocked pending demo

  useEffect(() => {
    getAuth().onAuthStateChanged(u => setfbUser(u))
  }, [])

  const { data: user, isLoading } = useQuery({
    queryKey: ['getUser'], queryFn: async () => await getUser(),
    enabled: queryClient.getQueryData(['getUser']) === undefined,
  })
  const isBlocked = user?.blocked_pending_demo

  useEffect(() => {
    const p = location.pathname
    setHide(
      (fbUser == null && (p == Path.SignIn || p == Path.SignUp || p == '/' || !p.includes(Path.PublicEvent)))
      || isBlocked
      || p === '/'
      || p.includes('/hi/')
      || p === Path.AuthRecallGoogleCallback
      || p === Path.AuthRecallMicrosoftCallback
      || p === Path.AuthWealthboxCallback
      || p === Path.AuthSalesforceCallback
      || p === Path.AuthSalesforceCallbackLocalhost
      || p === Path.AuthRingCentralCallback
      || p === Path.SignUp
      || p === Path.SignIn
      || p === Path.WebRecorder
    )
  }, [location, fbUser, isBlocked])

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        setDelayedIsLoading(false)
      }, 100)   // Set delayedIsLoading to false after 100ms
      return () => clearTimeout(timeoutId)
    }
  }, [isLoading]) // Run this effect whenever isLoading changes

  const sxDrawer: SxProps = {
    overflowX: 'hidden',
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth - 1,
      borderStyle: 'none',
      backgroundColor: gColor.backgroundColor,
      overflowX: 'hidden',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)', // Custom drop shadow
    },

  }

  if (hide)
    return null

  if (!user || delayedIsLoading)
    return (
      <Box sx={{ width: drawerWidth }}></Box>
    )

  return (
    <Box sx={{ overflowX: 'hidden !important' }}>
      {!open && (
        <Box sx={sxTab}>
          <IconButton onClick={() => setOpen(true)}>
            <Box component='img' sx={sxLogo} src={Media.logo100} />
          </IconButton>
        </Box>
      )}
      <Box
        component='nav'
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          overflowX: 'hidden',
        }}
      >

        <Drawer
          variant='temporary'
          open={open}
          onClose={() => setOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            ...sxDrawer
          }}
        >
          <NavContents
            drawerWidth={drawerWidth}
            setOpen={setOpen}
          />
        </Drawer>

        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            ...sxDrawer
          }}
          open
        >
          <NavContents
            drawerWidth={drawerWidth}
            setOpen={setOpen}
          />
        </Drawer>
      </Box>
    </Box>
  )
}

const sxTab: SxProps = {
  position: 'fixed',
  backgroundColor: 'rgba(255,255,255,.8)',
  height: '45px',
  marginTop: '10px',
  borderColor: 'black',
  borderTopWidth: '1px',
  borderBottomWidth: '1px',
  borderRightWidth: '1px',
  borderLeftWidth: '0px',
  borderStyle: 'solid',
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px',
}

const sxLogo: SxProps = {
  height: 30,
  resizeMode: 'contain',
  alignContent: 'center',
  paddingLeft: '5px',
  paddingRight: '10px',
}
