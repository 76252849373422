import React, { useState, ReactNode } from "react"
import { Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip, IconButton, Box, SxProps } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { MRT_TableContainer, type MRT_RowData, type MRT_TableInstance } from "material-react-table"

interface TableAccordionProps<T extends MRT_RowData> {
  title: string
  table: MRT_TableInstance<T>
  readOnly: boolean
  expanded: boolean
  setExpanded: (newExpanded: boolean) => void
}

const TableAccordion = <T extends MRT_RowData>({
  title,
  table,
  readOnly,
  expanded,
  setExpanded
}: TableAccordionProps<T>) => {
  const tableAcc: SxProps = {
    backgroundColor: "white",
    marginBottom: "8px",
    "&:last-child": {
      marginBottom: 0,
    },
    width: "calc(100% - 8px)",
    position: "relative", // Required for transform centering
    left: "50%",
    transform: "translateX(-50%)",
  }

  const tableAccSummary: SxProps = {
    flexDirection: "row-reverse",
    alignItems: "center",
    minHeight: "46px",
    padding: "0 15px",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  }
  const tableAccAddBox = (expanded: boolean): SxProps => ({
    marginLeft: "auto",
    transition: "opacity 0.3s ease, transform 0.3s ease",
    opacity: expanded ? 1 : 0,
    transform: expanded ? "translateY(0)" : "translateY(-10px)",
    pointerEvents: expanded ? "auto" : "none",
  })

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={tableAcc}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={tableAccSummary}
      >
        <Typography variant="h5" sx={{ flexGrow: 1, alignSelf: "center" }}>
          {title}
        </Typography>
        <Box sx={tableAccAddBox(expanded)}>
          {!readOnly && (
            <Tooltip title="Add">
              <IconButton onClick={(e) => {
                e.stopPropagation()
                table.setCreatingRow(true)
              }}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <MRT_TableContainer table={table} />
      </AccordionDetails>
    </Accordion>
  )
}

export default TableAccordion
