import { createTheme, SxProps } from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'

// https://mui.com/material-ui/customization/default-theme/

declare module '@mui/material/styles' {
  interface PaletteColor {
    100?: string
    200?: string
    300?: string
    700?: string
    800?: string
    900?: string
  }
  interface SimplePaletteColorOptions {
    100?: string
    200?: string
    300?: string
    700?: string
    800?: string
    900?: string
  }
}

export const gColor = {
  white: '#fbfeff',
  backgroundColor: '#FBFCFE',
  light: '#45CBDC',
  green500: '#349ead',
  successBanner: '#F5FFFA',
  errorBanner: 'lavenderblush',
  blue300: '#92B8BC',
  blue400: '#42CBDC',
  blue600: '#2B9EAD',
  blue800: '#28666E'
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Roboto, Arial, sans-serif',
    h1: {
      fontSize: 32,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 24,

    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 14,
    },
  },
  palette: {
    primary: {  // blues
      main: '#349ead',
      900: '#0A1929',
    },

    background: {
      default: '#e5ebef',
      // paper: '#FFF',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          padding: '24px',
          borderRadius: '5px',
          marginBottom: '2vh',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: 20,
        },
        root: {
          padding: 0,
          borderBottom: '1px solid #e0e0e0', // Adds a divider line
          paddingBottom: '16px', // Adjust padding to make room for the divider
          marginBottom: '16px', // Adjust padding to make room for the divider
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px'
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          marginBottom: '3px'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#e5ebef',
          margin: 0, // for small screens, remove margins.
          padding: 0
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingBottom: 15,
          fontSize: '20px',
          color: gColor.green500,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginRight: '15px',
          marginBottom: '15px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#F2F8F9',
          borderRadius: '10px',
          '&:before': {
            display: 'none', // Removes the default top border
          },
        },
      },
    }
  },
})

export const gSx: {
  RowBetween: SxProps,
  Row: SxProps,
  CenterPage: SxProps,
  CenterVertical: SxProps,
  RowTopAlign: SxProps,
  RowEnd: SxProps,
  Crm: {
    Accordian: SxProps,
    AccordianSummary: SxProps,
    LeftIcon: SxProps,
    Chip: (disabled?: boolean) => SxProps,
  }
} = {
  RowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  RowEnd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  CenterPage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  CenterVertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  RowTopAlign: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  Crm: {
    Accordian: {
      boxShadow: 'none',
      border: 'none',
      '&:before': {
        display: 'none',
      },
      backgroundColor: 'white',
      width: '99.9%',
      borderRadius: '10px',
      borderTopWidth: 0,
      marginY: 2,
    },
    AccordianSummary: {
      justifyContent: 'space-between',
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
      },
    },
    LeftIcon: {
      marginRight: 1,
      fontSize: 20
    },
    Chip: (disabled?: boolean) => ({
      cursor: 'pointer',
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      border: 1,
      borderRadius: 100,
      paddingY: 0.4,
      paddingLeft: 0.5,
      paddingRight: 1,
      borderColor: grey[400],
      '&:hover': {
        color: "primary.main",
        backgroundColor: grey[100]
      },
      opacity: disabled ? 0.5 : 1
    })
  }
}

// beyond headers, and body.
export const gSxText = {
  small: {
    fontSize: 14,
  } as SxProps,
}
