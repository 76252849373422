import React, { useEffect, useMemo, useRef, useState } from 'react'

import { MRT_EditActionButtons, type MRT_ColumnDef, type MRT_Row, type MRT_TableOptions, useMaterialReactTable } from 'material-react-table'

import DeleteIcon from '@mui/icons-material/Delete'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material'

import TableAccordion from './TableAccordion'
import { getNumberCell } from './CustomCells'
import { TravelGoal } from '../../client'
import { foldTable, hideTable, FinanceTableProps } from './financesUtils'

export default function TravelGoalTable({ eventId, title, type, subType, dataInput, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: FinanceTableProps<'Goals', 'Travel'>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<TravelGoal[]>([])

  const [newData, setNewData] = useState<TravelGoal>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput)
    }
  }, [dataInput])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const columns = useMemo<MRT_ColumnDef<TravelGoal>[]>(
    () => [
      {
        accessorKey: 'Description',
        header: 'Description',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Description': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Description'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        minSize: 250,
        size: 250
      },
      {
        accessorKey: 'For',
        header: 'For',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'For': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['For'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Priority',
        header: 'Priority',
        editSelectOptions: ["High", "Medium", "Low"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Priority': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Priority'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 25
      },
      {
        accessorKey: 'Frequency',
        header: 'Frequency',
        editSelectOptions: ["One Time", "Once a Year", "Multiple Times a Year"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Frequency': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Frequency'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Start Year',
        header: 'Start Year',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Start Year': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Start Year'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        size: 25
      },
      {
        accessorKey: 'Annual Amount Needed',
        header: 'Annual Amount Needed',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Annual Amount Needed': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Annual Amount Needed'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell(),
        size: 25
      },
    ],
    [dataList, newData],
  )

  const handleCreate: MRT_TableOptions<TravelGoal>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...dataList]
    if (newData) {
      updated.push(newData)
      onUpdate(type, subType, updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<TravelGoal>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, subType, updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: dataList,
    editDisplayMode: 'cell',
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: !readOnly,
    enableRowActions: !readOnly,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewData({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New {title}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
