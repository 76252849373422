import React, { useEffect, useRef, useState, MouseEventHandler } from 'react'
import { Box, SxProps, Card, Typography, Skeleton } from '@mui/material'
import { EventBase } from '../../client'
import { getSpeakerTotalTalk, getSpeakingTimelines } from '../../utils/eventAnalysis'
import {
  VictoryChart,
  VictoryAxis,
  VictoryArea,
  VictoryCursorContainer,
} from 'victory'
import { useStore } from '../../hooks/useStore'
import { isObjectEmpty } from '../../utils'
import { gSx } from '../../styles/Theme'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getEventTranscript } from 'service/eventApi'

interface Props {
  event: EventBase
}

export default function EventTimeline({ event }: Props) {
  const store = useStore()
  const queryClient = useQueryClient()

  const { data: transcript } = useQuery({
    queryKey: ['getEventTranscript', event.id],
    queryFn: async () => await getEventTranscript(event.id),
    enabled: queryClient.getQueryData(['getEventTranscript', event.id]) === undefined,
  })

  const speakerData = getSpeakingTimelines(transcript?.entries)
  const speakerTotal = getSpeakerTotalTalk(transcript?.entries)
  const [width, setWidth] = useState(window.innerWidth)
  const [cursorValue, setCursorValue] = useState(0)

  const updateWidth = (ev: any) => {
    setWidth(ev.target.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  const chartRef = useRef<any>(null)

  const handleCursorChange = (value: number) => {
    setCursorValue(value)
  }

  const handleMouseDown = () => {
    if (chartRef.current) {
      store.User.setSeekSeconds(cursorValue)
      store.User.setTranscriptSeekSeconds(cursorValue)
    }
  }

  return (
    <Card sx={sxCard}>
      <Typography variant='h4'>Talk time</Typography>
      <Typography variant='caption'>Click on the graph for transcript location</Typography>

      <Box sx={{ padding: '10px' }} />
      {
        isObjectEmpty(speakerData) ? (
          <Skeleton variant="rectangular" width={'100%'} height={150} />
        ) : (
          Object.entries(speakerData).map(([speaker, entries]) => (
            <Box key={speaker}>
              <Box sx={gSx.RowBetween}>
                <Typography sx={sxText}>{speaker}</Typography>
                {
                  speakerTotal[speaker] &&
                  <Typography sx={sxText}>{speakerTotal[speaker].summary}</Typography>
                }

              </Box>
              <div ref={chartRef} onMouseDown={handleMouseDown}>
                <VictoryChart
                  key={speaker}
                  domainPadding={0}
                  padding={{ top: 0, bottom: 30, left: 0, right: 0 }}
                  height={60}
                  width={width}
                  containerComponent={
                    <VictoryCursorContainer cursorDimension='x' onCursorChange={handleCursorChange} />
                  }
                >

                  <VictoryAxis tickFormat={() => ''} />
                  <VictoryArea
                    data={entries}
                    x='x'
                    y='y'
                    style={{
                      data: { fill: '#349EAD', strokeWidth: 0 }, // Change this to your desired line color
                    }}
                  />
                </VictoryChart>
              </div>
            </Box>
          ))
        )
      }
    </Card>
  )
}

const sxCard: SxProps = {

}

const sxText: SxProps = {
  color: '#1976d2',
  fontWeight: 'bold',
}