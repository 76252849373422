import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { PersonalDetails, Client, Employment, ClientDetails } from '../../client'
import PersonalDetailsTable from './PersonalDetailsTable'
import EmploymentTable from './EmploymentTable'
import SubCard from 'components/SubCard'
interface Props {
  eventId?: string
  clientInfo?: Client
  index: number
  readOnly: boolean
  onUpdated: (idx: number, updatedClientInfo: Client) => (void)
}

export default function ClientInfo({ eventId, clientInfo, index, readOnly, onUpdated }: Props) {

  // const cellRef = useRef(null)
  const [personalDetails, setPersonalDetails] = useState<PersonalDetails[]>([])
  const [employment, setEmployment] = useState<Employment[]>([])
  const [fullName, setFullName] = useState<string>("")

  useEffect(() => {
    if (clientInfo && clientInfo['Client Details']) {
      if (clientInfo['Client Details']['Personal Details'] && clientInfo['Client Details']?.['Personal Details'].length > 0 && clientInfo['Client Details']['Personal Details'][0]['Full Name']) {
        setFullName(clientInfo['Client Details']['Personal Details'][0]['Full Name'])
      }
      if (clientInfo['Client Details']["Personal Details"]) {
        setPersonalDetails(clientInfo['Client Details']["Personal Details"])
      }
    }

    if (clientInfo && clientInfo['Employment & Income'] && clientInfo['Employment & Income'].Employment) {
      setEmployment(clientInfo['Employment & Income'].Employment)
    }
  }, [clientInfo])

  function onUpdatePersonalDetails(updatedPersonalDetails: PersonalDetails[]) {
    console.log("updatePersonalDetailsTable", updatedPersonalDetails)
    const updatedClientInfo = { ...clientInfo }
    if (!updatedClientInfo['Client Details'])
      updatedClientInfo['Client Details'] = {}
    updatedClientInfo['Client Details']["Personal Details"] = updatedPersonalDetails
    onUpdated(index, updatedClientInfo)
  }

  function onUpdateEmployment(updatedEmployment: Employment[]) {
    console.log("onUpdateEmployment", updatedEmployment)
    const updatedClientInfo = { ...clientInfo }
    if (!updatedClientInfo['Employment & Income'])
      updatedClientInfo['Employment & Income'] = {}
    updatedClientInfo['Employment & Income']["Employment"] = updatedEmployment
    onUpdated(index, updatedClientInfo)
  }

  return (
    <SubCard>
      <CardHeader
        title={fullName}
        sx={{ paddingTop: 1 }}
      />

      <PersonalDetailsTable eventId={eventId} personalDetailsList={personalDetails} onUpdate={onUpdatePersonalDetails} readOnly={readOnly} />
      <Box sx={{ padding: '1vh' }} />
      <EmploymentTable eventId={eventId} employmentList={employment} onUpdate={onUpdateEmployment} readOnly={readOnly} />
    </SubCard>

  )
}
