// import {
//   Configuration,
//   DefaultApi
// } from '../sdk-ts-axios'
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import { getAuth } from 'firebase/auth'
import { AppClient } from '../client'
import { getStorage } from 'firebase/storage'

// env-cmd requires `REACT_APP` prefix
// any key added here, need to also add to github actions CI/CD
export const config = {
  env: process.env.REACT_APP_ENV,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  microsoftClientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
  api: process.env.REACT_APP_API,
  apiKey: process.env.REACT_APP_API_KEY, // for stage/prod. get from Gitlab CI Vars
  callbackHost: process.env.REACT_APP_CALLBACK_HOST,
  web: process.env.REACT_APP_WEB,
  hosting: `${process.env.REACT_APP_PROJECT_ID}.web.app`,
  authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '',
  zoomClientId: process.env.REACT_APP_ZOOM_CLIENT_ID ?? '',
  zoomRedirect: process.env.REACT_APP_ZOOM_REDIRECT ?? '',
  wealthboxClientId: process.env.REACT_APP_WEALTHBOX_CLIENT_ID ?? '',
  wealthboxRedirect: process.env.REACT_APP_WEALTHBOX_REDIRECT ?? '',
  salesforceClientId: process.env.REACT_APP_SALESFORCE_CLIENT_ID ?? '',
  salesforceRedirect: process.env.REACT_APP_SALESFORCE_REDIRECT ?? '',
  ringcentralClientId: process.env.REACT_APP_RINGCENTRAL_CLIENT_ID ?? '',
  ringcentralRedirect: process.env.REACT_APP_RINGCENTRAL_REDIRECT ?? '',
  preciseFpClientId: process.env.REACT_APP_PRECISEFP_CLIENT_ID ?? '',
  preciseFpRedirect: process.env.REACT_APP_PRECISEFP_REDIRECT ?? ''
}

if (config.env !== 'prod') {
  console.log('config does not show on prod')
  console.log('config.env', config.env)
  console.log('config.api', config.api)
  console.log('config.projectId', config.projectId)
}

const app = initializeApp(config)
export const auth = getAuth(app)
export const messaging = getMessaging()
export const storage = getStorage(app)

// https://github.com/ferdikoomen/openapi-typescript-codegen/blob/master/docs/client-instances.md

const apiConfig = async () => {
  const token = await auth.currentUser?.getIdToken()
  return new AppClient({
    BASE: config.api,
    TOKEN: token
  })
}

export const Api = async () => await apiConfig()
