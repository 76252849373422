import React, { useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { SxProps, Tab } from '@mui/material'
import ClientCardForEvent from 'components/client/ClientCardForEvent'
import Attendees from './Attendees'
import { EventBase } from 'client'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { getEventBot } from 'service/eventApi'

interface Props {
  event?: EventBase
  disabled?: boolean
}

export default function ClientAttendeeTabs({ event, disabled }: Props) {
  const queryClient = new QueryClient()
  const [tabValue, setTabValue] = useState("1")

  const { data: bot } = useQuery({
    queryKey: ['getEventBot', event?.id],
    queryFn: async () => await getEventBot(event?.id),
    enabled: queryClient.getQueryData(['getEventBot', event?.id]) === undefined,
  })

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const hasAttendees = (bot?.meeting_participants && bot?.meeting_participants.length > 0)

  return (
    <TabContext value={tabValue}>
      <TabList sx={sxTabList} onChange={handleChange}>
        <Tab
          sx={sxTabList}
          label='Contact'
          value='1'
        />
        {
          hasAttendees &&
          <Tab
            sx={sxTabList}
            label='Attendees'
            value='2'
          />
        }

      </TabList>
      <TabPanel sx={sxTabPanel} value='1'>
        <ClientCardForEvent event={event} disabled={disabled} />
      </TabPanel>
      <TabPanel sx={sxTabPanel} value='2'>
        <Attendees bot={bot} />
      </TabPanel>
    </TabContext>
  )
}

const sxTabList: SxProps = {
  minHeight: '30px',
  marginLeft: 0.5
}

const sxTabPanel: SxProps = {
  padding: '0',
}