import React, { useEffect } from 'react'
import { Box, CircularProgress, SxProps, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Media } from 'service/media'
import { setRingCentralAuth } from 'service/integrations/ringCentralApi'
import LinkIcon from '@mui/icons-material/Link'
import { gSx } from 'styles/Theme'
import { logInfo } from 'log'

export default function RingCentralCallback() {
  const url = window.location.href
  const urlParams = new URLSearchParams(new URL(url).search)
  const code = urlParams.get("code")
  const errorDesc = urlParams.get("error_description")
  const errorUrl = urlParams.get('error')

  useEffect(() => {
    logInfo('RingCentralCallback', { url })
  }, [])

  const { data, isLoading } = useQuery({
    queryKey: ['setRingCentralAuth'],
    queryFn: async () => {
      if (!!errorUrl || !!errorDesc)
        return
      if (!code) // should never happen. has always accompanied 'error' param.
        return new Error('missing auth code')
      return await setRingCentralAuth(code)
    },
    refetchOnWindowFocus: false
  })
  const isError = (!!errorUrl || !!errorDesc || data instanceof Error)

  return (
    <Box sx={gSx.CenterPage}>
      <Box sx={gSx.Row}>
        <Box component='img' sx={sxIcon} src={Media.logo100} />
        <LinkIcon />
        <Box component="img" sx={sxIcon} src={Media.ringCentralLogo} alt="Ring Central" />
      </Box>

      <Box sx={{ padding: 2 }} />

      {
        isLoading ? (
          <Box sx={gSx.CenterVertical}>
            <Typography variant='h4'>Finalizing Finmate and RingCentral Connections</Typography>
            <Box sx={{ padding: '10px' }} />
            <CircularProgress />
          </Box>
        ) : (
          (isError)
            ?
            <>
              <Typography variant='h4'>Something went wrong. Please Try Again.
              </Typography>
              {(data instanceof Error) &&
                <Typography color={'tomato'}>{data?.message}</Typography>
              }
              {errorUrl && <Typography color={'tomato'}>{errorUrl}</Typography>}
              {errorDesc && <Typography color={'tomato'}>{errorDesc}</Typography>}
            </>
            : <>
              <Typography variant='h4'>RingCentral is Authenticated</Typography>
              <Box sx={{ padding: '10px' }} />
              <Typography>You can close this window</Typography>
            </>
        )
      }
    </Box >
  )
}

const sxIcon: SxProps = {
  height: '50px',
  marginX: 3
}
