import React, { useEffect, useState } from 'react'
import { Box, LinearProgress, SxProps, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { exchangeAuthCodeForTokens, isSalesforceApp } from 'service/integrations/salesforceApi'
import SalesforceFinmateConnectIcons from './SalesforceFinmateConnectIcons'
import { SalesforceApp } from 'client'
import { gSx } from 'styles/Theme'

export default function AuthSalesforceCallback() {
  const url = window.location.href
  const urlParams = new URLSearchParams(new URL(url).search)
  const code = urlParams.get("code") ?? undefined
  const queryClient = useQueryClient()
  const [err, setErr] = useState<string | undefined>()

  const [app, setApp] = useState(SalesforceApp.SALESFORCE)
  const storageItem = localStorage.getItem('SalesforceApp')

  useEffect(() => {
    if (storageItem != null)
      setApp(storageItem as SalesforceApp)
  }, [storageItem])

  const { data, isLoading } = useQuery({
    queryKey: ['exchangeAuthCodeForTokens', code],
    queryFn: async () => await exchangeAuthCodeForTokens(code),
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (data instanceof Error) {
      setErr(data.message)
    } else if (isSalesforceApp(data)) {
      queryClient.invalidateQueries({ queryKey: ['getSalesforceSettings'] })
    }
  }, [data])

  if (isLoading) {
    return (
      <Box sx={gSx.CenterPage}>
        <SalesforceFinmateConnectIcons />
        <Typography variant='h3'>Finalizing {app} Connections</Typography>
        <Box sx={{ width: 200, paddingY: '2vh' }}>
          <LinearProgress />
        </Box>
      </Box>
    )
  }

  if (isSalesforceApp(data))
    return (
      <Box sx={gSx.CenterPage}>
        <SalesforceFinmateConnectIcons />
        <Typography variant='h3'>{`You are signed into ${app}`}</Typography>
        <Typography variant='h4'>You may close this window</Typography>
      </Box >
    )

  return (
    <Box sx={gSx.CenterPage}>
      <SalesforceFinmateConnectIcons />
      <Typography variant='h3'>Something went wrong</Typography>
      <Typography sx={{ color: 'tomato' }}>{err}</Typography>
    </Box >
  )
}
