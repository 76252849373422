import { ZoomBase } from "client"
import { logErr } from "log"
import { Api } from "service/api"
import { getErrorMessage } from "utils"

export async function getZoomSettings(): Promise<ZoomBase | undefined> {
  try {
    const api = await Api()
    return await api.zoom.getZoomSettings()
  } catch (e: any) {
    const err = getErrorMessage(e)
    logErr('getZoomSettings', { err })
  }
}

export async function setZoomAuthCred(code?: string): Promise<ZoomBase | Error> {
  if (!code)
    return new Error('missing zoom code')
  try {
    const api = await Api()
    return await api.zoom.setZoomAuthCred(code)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setZoomAuthCred', { err })
    return err
  }
}

export async function setZoomEnableRecording(enable: boolean): Promise<ZoomBase | Error> {
  try {
    const api = await Api()
    return await api.zoom.setEnablePhone(enable)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setZoomEnableRecording', { err })
    return err
  }
}

export async function deauthorizeZoom(): Promise<undefined | Error> {
  try {
    const api = await Api()
    await api.zoom.deauthorizeZoom()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('deauthorizeZoom', { err })
    return err
  }
}