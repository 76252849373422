import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Skeleton, Divider, Container, Button, CardHeader } from '@mui/material'
import 'firebase/auth'
import { Media } from '../../service/media'
import { useQuery } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import { gSx } from '../../styles/Theme'
import Grid from '@mui/material/Unstable_Grid2'
import { logInfo } from 'log'
import { LoadingButton } from '@mui/lab'
import { disconnect, getSalesforceSettings, salesforceOAuth } from 'service/integrations/salesforceApi'
import { sleep } from 'utils'
import { SalesforceApp } from 'client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import SalesforceImportContacts from 'components/integrations/salesforce/SalesforceImportContacts'
import { Path } from 'routes'
import ContainerMedium from 'components/ContainerMedium'

export default function Xlr8SettingsScreen() {
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  useEffect(() => {
    logInfo('Salesforce Settings Screen')
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
  })
  const isSignedIn = !!data?.scope

  async function onSignIn() {
    setBusy(true)
    await salesforceOAuth(SalesforceApp.XLR8)
    await sleep(10000)
    setBusy(false)
  }

  async function signout() {
    logInfo('Xlr8 Sign out Pressed')
    setBusy(true)
    const res = await disconnect()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await refetch()
    }
    setBusy(false)
  }

  return (
    <ContainerMedium>
      <Button
        onClick={() => navigate(Path.Integrations)}
        startIcon={<ArrowBackIcon />}
      >
        Integrations
      </Button>
      <Box sx={{ padding: 2 }} />
      <Typography variant='h3'>Xlr8 Integration</Typography>
      <Divider sx={{ marginY: '2vh ' }} />
      <Link href="https://xlr8crm.com" rel='noopener' target='_blank'>
        <Box
          component="img"
          sx={sxLogo}
          src={Media.xlr8}
        />
      </Link>
      <Box sx={{ padding: '1vh ' }} />

      <Typography>Connect FinMate AI to XLR8 (Powered by Salesforce), to easily keep your records up to date. </Typography>
      <Box sx={{ padding: '1vh ' }} />
      <Typography>Effortlessly sync AI-generated Meeting Summaries and Tasks with XLR8.</Typography>
      <Box sx={{ padding: '1vh ' }} />

      <Typography variant='h6'>Events</Typography>
      <Typography>FinMate AI Meetings Summaries are saved as XLR8 Events.</Typography>
      <Box sx={{ padding: '1vh ' }} />

      <Typography variant='h6'>Tasks</Typography>
      <Typography>FinMate AI Tasks are saved as XLR8 Tasks.</Typography>
      <Box sx={{ padding: '1vh ' }} />

      <Card>

        {isLoading ? (
          <Skeleton variant="rectangular" width={'100%'} height={100} />
        ) : (
          data?.scope ? (
            <Box sx={gSx.RowBetween}>
              <Typography>{`Signed in as `}<b>{`${data.username}`}</b></Typography>
              <Tooltip title="Disconnect Xlr8">
                <LoadingButton
                  aria-label='Disconnect Xlr8'
                  onClick={signout}
                  disabled={busy}
                  loading={busy}
                >
                  <CloseIcon />
                </LoadingButton>
              </Tooltip>
            </Box>

          ) : (
            <Box sx={sxConnect}>
              <LoadingButton
                variant='contained' onClick={onSignIn}
                loading={busy}
                disabled={busy}
                sx={{ width: 150 }}
              >
                Connect
              </LoadingButton>
              <Typography variant='caption'>Powered by Salesforce</Typography>
            </Box>
          )
        )}

        {
          err && <Typography sx={{ color: 'tomato' }}>
            {err}
          </Typography>
        }

      </Card >

      {isSignedIn &&
        <Card>
          <CardHeader title='Import Contacts' />
          <SalesforceImportContacts app={SalesforceApp.XLR8} />
          <Box sx={{ padding: 0.5 }} />
          <Typography variant='caption'>Only contacts with emails are imported.</Typography>
        </Card>
      }

    </ContainerMedium>
  )
}

const sxLogo: SxProps = {
  width: 150,
}

const sxConnect: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}