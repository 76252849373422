import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Box, IconButton, Tooltip,
} from '@mui/material'
import {
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { BankAccount } from '../../client'
import DeleteIcon from '@mui/icons-material/Delete'
import { hideTable, foldTable } from './financesUtils'
import TableAccordion from './TableAccordion'
import { getNumberCell } from './CustomCells'

interface Props {
  eventId?: string
  bankAccountListInput?: BankAccount[]
  foldEmptyTable: boolean
  hideEmptyTable: boolean
  readOnly: boolean
  onUpdate: (updated: BankAccount[]) => (void)
}

export default function BankAccountsTable({ eventId, bankAccountListInput, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: Props) {

  const [expanded, setExpanded] = useState(true)
  const [bankAccountList, setBankAccountList] = useState<BankAccount[]>([])
  const [newBankAccount, setNewBankAccount] = useState<BankAccount>()

  useEffect(() => {
    if (bankAccountListInput) {
      console.log("bankAccountList", bankAccountListInput)
      setBankAccountList(bankAccountListInput)
    }
  }, [bankAccountListInput])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, bankAccountList))
  }, [foldEmptyTable, bankAccountList])

  const columns = useMemo<MRT_ColumnDef<BankAccount>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                Name: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...bankAccountList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 130
      },
      {
        accessorKey: 'Primary',
        header: 'Primary',
        editSelectOptions: ["Yes", "No"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                Primary: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...bankAccountList]
            updated[rowIdx]['Primary'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 25
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                Type: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...bankAccountList]
            updated[rowIdx]['Type'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                Owner: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...bankAccountList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Average Balance',
        header: 'Average Balance',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Average Balance': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...bankAccountList]
            updated[rowIdx]['Average Balance'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        Cell: getNumberCell(),
        size: 50
      },
      {
        accessorKey: 'Bank Name',
        header: 'Bank Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Bank Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...bankAccountList]
            updated[rowIdx]['Bank Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Bank Address',
        header: 'Bank Address',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Bank Address': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...bankAccountList]
            updated[rowIdx]['Bank Address'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [bankAccountList, newBankAccount],
  )

  const handleCreate: MRT_TableOptions<BankAccount>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...bankAccountList]
    if (newBankAccount) {
      updated.push(newBankAccount)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<BankAccount>) {
    const updated = [...bankAccountList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: bankAccountList,
    editDisplayMode: 'cell',
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: !readOnly,
    enableRowActions: !readOnly,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewBankAccount({}),
    onCreatingRowSave: handleCreate,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  if (hideTable(hideEmptyTable, bankAccountList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title="Bank Accounts" table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
