import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Box, IconButton, Tooltip,
  Button,
} from '@mui/material'
import {
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { Child } from '../../client'
import DeleteIcon from '@mui/icons-material/Delete'
import { hideTable, foldTable } from './financesUtils'
import TableAccordion from './TableAccordion'

interface Props {
  eventId?: string
  childListInput?: Child[]
  foldEmptyTable: boolean
  hideEmptyTable: boolean
  readOnly: boolean
  onUpdate: (updated: Child[]) => (void)
}

export default function ChildrenTable({ eventId, childListInput, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: Props) {

  const [expanded, setExpanded] = useState(true)
  const [childList, setChildList] = useState<Child[]>([])
  const [newChild, setNewChild] = useState<Child>()

  useEffect(() => {
    if (childListInput) {
      setChildList(childListInput)
    }
  }, [childListInput])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, childList))
  }, [foldEmptyTable, childList])

  const columns = useMemo<MRT_ColumnDef<Child>[]>(
    () => [
      {
        accessorKey: 'First Name',
        header: 'First Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewChild((prevNewChild) => ({
                ...prevNewChild,
                'First Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...childList]
            updated[rowIdx]['First Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Last Name',
        header: 'Last Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewChild((prevNewChild) => ({
                ...prevNewChild,
                'Last Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...childList]
            updated[rowIdx]['Last Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Gender',
        header: 'Gender',
        editSelectOptions: ["M", "F"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewChild((prevNewChild) => ({
                ...prevNewChild,
                Gender: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...childList]
            updated[rowIdx]['Gender'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Birthdate',
        header: 'Birthdate', // TODO: format check
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewChild((prevNewChild) => ({
                ...prevNewChild,
                Birthdate: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...childList]
            updated[rowIdx]['Birthdate'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [childList, newChild],
  )

  const handleCreate: MRT_TableOptions<Child>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...childList]
    if (newChild) {
      updated.push(newChild)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<Child>) {
    const updated = [...childList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: childList,
    editDisplayMode: 'cell',
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: !readOnly,
    enableRowActions: !readOnly,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewChild({}),
    onCreatingRowSave: handleCreate,
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true) // simplest way to open the create row modal with no default values
        }}
      >
        Create New User
      </Button>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  if (hideTable(hideEmptyTable, childList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title="Children" table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
