import React from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getCalendarEvents, isZoomScheduled } from "service/calendarApi"
import ZoomCardForRecorder from "./ZoomCardForRecorder"
import { getZoomSettings } from "service/integrations/zoomApi"
import { Box } from "@mui/material"

const ZoomSetupAtHome = () => {
  const queryClient = useQueryClient()

  const { data: calEvents } = useQuery({
    queryKey: ['getCalendarEvents'], queryFn: getCalendarEvents,
    enabled: queryClient.getQueryData(['getCalendarEvents']) === undefined,
  })
  const hasZoomScheduled = isZoomScheduled(calEvents?.events)

  const { data: zoom } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
    enabled: queryClient.getQueryData(['getZoomSettings']) === undefined,
  })

  if (hasZoomScheduled && !zoom?.isVideoReady)
    return (
      <>
        <ZoomCardForRecorder />
        <Box sx={{ padding: 1 }} />
      </>
    )

  return null
}

export default ZoomSetupAtHome
