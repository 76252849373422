import React, { useEffect, useState } from 'react'
import { Box, SxProps, Card, Typography, Switch, Snackbar, Alert, CardHeader } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUserConfig, updatePremeetingSettings, UserConfigKey } from 'service'
import { gSx } from 'styles/Theme'
import { SkeletonRows } from 'components/Skeletons'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { useTranslation } from 'react-i18next'

export default function PremeetingSettingsCard() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
  })

  async function onUpdatePremeetingSettings(key: UserConfigKey, checked: boolean) {
    setErr(undefined)
    setBusy(true)
    const res = await updatePremeetingSettings(key, checked)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
      setSnackSuccess(true)
    }
    setBusy(false)
  }

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>
      <CardHeader title={t("Pre-meeting Note Settings")} />

      <Box sx={{ paddingY: 1 }} />

      {
        isLoading
          ? <SkeletonRows count={1} height={65} />
          : <>
            <Box sx={gSx.Row}>
              <Switch
                checked={data?.enable_premeeting_notes}
                onChange={(event, checked) => onUpdatePremeetingSettings(UserConfigKey.EnablePremeetingNotes, checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={busy}
              />
              <Box>
                <Box sx={gSx.Row}>
                  <Typography variant='h6'>{t("Enable Pre-meeting Notes")}</Typography>
                  <EventNoteIcon sx={{ paddingLeft: "4px", color: 'grey' }} />
                  <Typography sx={{ color: 'tomato', paddingLeft: 1, fontWeight: 'bold' }} variant='caption'>{t("Beta")}</Typography>
                </Box>
                <Typography>{t("Enable Pre-meeting Notes Description")}</Typography>
              </Box>
            </Box>
          </>
      }

      <Box sx={{ paddingY: 1 }} />

      {err && <Typography sx={{ color: 'tomato' }}>{err}</Typography>}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          {t("Pre-meeting Notes Settings Updated")}
        </Alert>

      </Snackbar>
    </Card >
  )
}
