import React, { useState, useEffect, useMemo } from 'react'

import {
  Box, IconButton, Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { RetirementPlanIndividual } from '../../client'
import DeleteIcon from '@mui/icons-material/Delete'
import { hideTable, foldTable } from './financesUtils'
import TableAccordion from './TableAccordion'

interface Props {
  eventId?: string
  title: string
  type: string
  retirementPlanIndividualListInput?: RetirementPlanIndividual[]
  foldEmptyTable: boolean
  hideEmptyTable: boolean
  readOnly: boolean
  onUpdate: (type: string, updated: RetirementPlanIndividual[]) => (void)
}

export default function RetirementPlansIndividualTable({ eventId, title, type, retirementPlanIndividualListInput, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: Props) {

  const [expanded, setExpanded] = useState(true)
  const [retirementPlanIndividualList, setRetirementPlanIndividualList] = useState<RetirementPlanIndividual[]>([])

  const [newRetirementPlanIndividual, setNewRetirementPlanIndividual] = useState<RetirementPlanIndividual>()

  useEffect(() => {
    if (retirementPlanIndividualListInput) {
      console.log("retirementPlanIndividualList", retirementPlanIndividualListInput)
      setRetirementPlanIndividualList(retirementPlanIndividualListInput)
    }
  }, [retirementPlanIndividualListInput])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, retirementPlanIndividualList))
  }, [foldEmptyTable, retirementPlanIndividualList])

  const columns = useMemo<MRT_ColumnDef<RetirementPlanIndividual>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewRetirementPlanIndividual((prevPlan) => ({
                ...prevPlan,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...retirementPlanIndividualList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(type, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewRetirementPlanIndividual((prevPlan) => ({
                ...prevPlan,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...retirementPlanIndividualList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(type, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Contributions',
        header: 'Contributions',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewRetirementPlanIndividual((prevPlan) => ({
                ...prevPlan,
                'Contributions': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...retirementPlanIndividualList]
            updated[rowIdx]['Contributions'] = Number(event.target.value)
            onUpdate(type, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Account Value',
        header: 'Account Value',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewRetirementPlanIndividual((prevPlan) => ({
                ...prevPlan,
                'Account Value': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...retirementPlanIndividualList]
            updated[rowIdx]['Account Value'] = Number(event.target.value)
            onUpdate(type, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Primary Beneficiary',
        header: 'Primary Beneficiary',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewRetirementPlanIndividual((prevPlan) => ({
                ...prevPlan,
                'Primary Beneficiary': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...retirementPlanIndividualList]
            updated[rowIdx]['Primary Beneficiary'] = event.target.value
            onUpdate(type, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Secondary Beneficiary',
        header: 'Secondary Beneficiary',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewRetirementPlanIndividual((prevPlan) => ({
                ...prevPlan,
                'Secondary Beneficiary': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...retirementPlanIndividualList]
            updated[rowIdx]['Secondary Beneficiary'] = event.target.value
            onUpdate(type, updated)
          },
        }),
        size: 100
      },
    ],
    [retirementPlanIndividualList, newRetirementPlanIndividual],
  )

  const handleCreate: MRT_TableOptions<RetirementPlanIndividual>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...retirementPlanIndividualList]
    if (newRetirementPlanIndividual) {
      updated.push(newRetirementPlanIndividual)
      onUpdate(type, updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<RetirementPlanIndividual>) {
    const updated = [...retirementPlanIndividualList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: retirementPlanIndividualList,
    editDisplayMode: 'cell',
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: !readOnly,
    enableRowActions: !readOnly,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewRetirementPlanIndividual({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New {title}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  if (hideTable(hideEmptyTable, retirementPlanIndividualList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
