import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, Box, Snackbar, Alert, Typography,
} from '@mui/material'
import { ClientData, PersonalProperty, OtherAsset, BankAccount, InvestmentAccount, CertificateOfDeposit, CollegeFund, RetirementPlanEmployer, RetirementPlans, RetirementPlanIndividual, Pension, Client, Child, EventBase, SummaryProcessingStatus, ProcessingStatus, BusinessAsset, Liabilities, Goals } from '../../client'
import ChildrenTable from 'components/finances/ChildrenTable'
import PersonalPropertyTable from 'components/finances/PersonalPropertyTable'
import BankAccountsTable from 'components/finances/BankAccountsTable'
import InvestmentAccountsTable from 'components/finances/InvestmentAccountsTable'
import CertificatesOfDepositTable from 'components/finances/CertificatesOfDepositTable'
import CollageFundTable from 'components/finances/CollageFundTable'
import RetirementPlansEmployerTable from 'components/finances/RetirementPlansEmployerTable'
import CreditCardsTable from './CreditCardsTable'
import MortgagesTable from './MortgagesTable'
import LoansTable from './LoansTable'
import RetirementGoalTable from './RetirementGoalTable'
import TravelGoalTable from './TravelGoalTable'
import WeddingGoalTable from './WeddingGoalTable'
import CelebrationGoalTable from './CelebrationGoalTable'
import CollegeGoalTable from './CollegeGoalTable'
import PrivateSchoolGoalTable from './PrivateSchoolGoalTable'
import NewBusinessGoalTable from './NewBusinessGoalTable'
import NewHomeGoalTable from './NewHomeGoalTable'
import HomeImprovementGoalTable from './HomeImprovementGoalTable'
import MajorPurchaseGoalTable from './MajorPurchaseGoalTable'
import ProvideCareGoalTable from './ProvideCareGoalTable'
import GiftOrDonationGoalTable from './GiftOrDonationGoalTable'
import OtherGoalTable from './OtherGoalTable'
import LeaveBequestGoalTable from './LeaveBequestTable'
import RetirementPlansIndividualTable from 'components/finances/RetirementPlansIndividualTable'
import PensionTable from 'components/finances/PensionTable'
import ClientInfo from 'components/finances/ClientInfo'
import OtherAssetTable from 'components/finances/OtherAssetTable'
import BusinessAssetTable from 'components/finances/BusinessAssetTable'
import { hideTitle } from './financesUtils'

interface Props {
  eventId: string
  clientData?: ClientData
  updatePfClientData?: (eventId: string, updatedClientData: ClientData) => (void)
  readOnly: boolean
  foldEmptyTables: boolean
  hideEmptyTables: boolean
}

export default function FinancesTables({ eventId, clientData, updatePfClientData, readOnly, hideEmptyTables, foldEmptyTables }: Props) {

  const [openErrorSnack, setOpenErrorSnack] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState<string | undefined>()

  async function onUpdatedClientInfo(idx: number, clientInfo: Client) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Clients && updatedClientData.Clients[idx])
        updatedClientData.Clients[idx] = clientInfo
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedChildren(updatedChildList: Child[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData['Family Members'])
        updatedClientData['Family Members'].Children = updatedChildList
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedBankAccounts(updatedBankAccounts: BankAccount[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Bank Accounts'] = updatedBankAccounts
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedCD(updatedCD: CertificateOfDeposit[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Certificates of Deposit'] = updatedCD
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdateInvestmentAccounts(updated: InvestmentAccount[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Taxable Investment Accounts'] = updated
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdateCollageFund(updated: CollegeFund[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['College Funds'] = updated
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatePersonalProperty(updated: PersonalProperty[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Personal Property'] = updated
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdateBusinessAsset(updated: BusinessAsset[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Business Assets'] = updated
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdateOtherAsset(updated: OtherAsset[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Other Assets'] = updated
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedRetirementPlansEmployer(type: string, updatedRPEs: RetirementPlanEmployer[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData['Retirement Plans'])
        updatedClientData['Retirement Plans'][type as keyof RetirementPlans] = updatedRPEs
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedRetirementPlanIndividual(type: string, updatedRPEs: RetirementPlanIndividual[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData['Retirement Plans'])
        updatedClientData['Retirement Plans'][type as keyof RetirementPlans] = updatedRPEs
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatePensions(updatedPensions: Pension[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData['Retirement Plans'])
        updatedClientData['Retirement Plans'].Pensions = updatedPensions
      if (updatePfClientData)
        await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedData<
    P extends keyof ClientData,
    S extends keyof NonNullable<ClientData[P]>,
  >(
    type: P,
    subType: S,
    updated: NonNullable<ClientData[P]>[S],
  ) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (!updatedClientData[type]) {
        updatedClientData[type] = {} as NonNullable<ClientData[P]>
      }
      if (updatedClientData[type]) {
        (updatedClientData[type] as NonNullable<ClientData[P]>)[subType] = updated
      }
      if (updatePfClientData) {
        await updatePfClientData(eventId, updatedClientData)
      }
    }
  }

  return (
    <>

      <Box sx={stickyHeading}>
        <Typography variant="h5">Client & Co-Client</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Client&apos;s personal and employment details
        </Typography>
      </Box>

      {clientData?.Clients?.map((client: Client, idx) => (
        <>
          <ClientInfo eventId={eventId} clientInfo={client} index={idx} onUpdated={onUpdatedClientInfo} readOnly={readOnly} />
        </>
      ))}
      {
        !hideTitle(hideEmptyTables, clientData?.['Family Members']) &&
        <Box sx={stickyHeading}>
          <Typography variant="h5">Family Members</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Children, other family members
          </Typography>
        </Box>
      }

      <ChildrenTable eventId={eventId} childListInput={clientData?.['Family Members']?.Children} onUpdate={onUpdatedChildren} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.Assets) &&
        <Box sx={stickyHeading}>
          <Typography variant="h4">Assets</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Bank Accounts, Certificates Of Deposit, Investment Accounts, Collage Fund, Personal Properties
          </Typography>
        </Box>
      }

      <BankAccountsTable eventId={eventId} bankAccountListInput={clientData?.Assets?.['Bank Accounts']} onUpdate={onUpdatedBankAccounts} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <CertificatesOfDepositTable eventId={eventId} certificateOfDepositListInput={clientData?.Assets?.['Certificates of Deposit']} onUpdate={onUpdatedCD} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <InvestmentAccountsTable eventId={eventId} investmentAccountListInput={clientData?.Assets?.['Taxable Investment Accounts']} onUpdate={onUpdateInvestmentAccounts} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <CollageFundTable eventId={eventId} collegeFundListInput={clientData?.Assets?.['College Funds']} onUpdate={onUpdateCollageFund} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <PersonalPropertyTable eventId={eventId} personalPropertyListInput={clientData?.Assets?.['Personal Property']} onUpdate={onUpdatePersonalProperty} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <BusinessAssetTable eventId={eventId} dataListInput={clientData?.Assets?.['Business Assets']} onUpdate={onUpdateBusinessAsset} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <OtherAssetTable eventId={eventId} dataListInput={clientData?.Assets?.['Other Assets']} onUpdate={onUpdateOtherAsset} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.['Retirement Plans']) &&
        <Box sx={stickyHeading}>
          <Typography variant="h4">Retirement Plans</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            401(k), Roth 401(k), 403(b), 456(b), Simple IRA, SEP IRA, IRA, Roth IRA, Pensions
          </Typography>
        </Box>
      }

      <RetirementPlansEmployerTable title="401(k)" type={'401(k)'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['401(k)']} onUpdate={onUpdatedRetirementPlansEmployer} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="Roth 401(k)" type={'Roth 401(k)'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['Roth 401(k)']} onUpdate={onUpdatedRetirementPlansEmployer} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="403(b)" type={'403(b)'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['403(b)']} onUpdate={onUpdatedRetirementPlansEmployer} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="457(b)" type={'457(b)'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['457(b)']} onUpdate={onUpdatedRetirementPlansEmployer} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="Simple IRA" type={'Simple IRA'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['Simple IRA']} onUpdate={onUpdatedRetirementPlansEmployer} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansEmployerTable title="SEP IRA" type={'SEP IRA'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['SEP IRA']} onUpdate={onUpdatedRetirementPlansEmployer} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansIndividualTable title="IRA" type={'IRA'} eventId={eventId} retirementPlanIndividualListInput={clientData?.['Retirement Plans']?.IRA} onUpdate={onUpdatedRetirementPlanIndividual} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <RetirementPlansIndividualTable title="Roth IRA" type={'Roth IRA'} eventId={eventId} retirementPlanIndividualListInput={clientData?.['Retirement Plans']?.['Roth IRA']} onUpdate={onUpdatedRetirementPlanIndividual} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <PensionTable eventId={eventId} pensionListInput={clientData?.['Retirement Plans']?.Pensions} onUpdate={onUpdatePensions} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.Liabilities) &&
        <Box sx={stickyHeading}>
          <Typography variant="h4">Liabilities</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Credit Cards, Mortgages, Loans
          </Typography>
        </Box>
      }

      <CreditCardsTable title="Credit Cards" type={'Liabilities'} subType={'Credit Cards'} eventId={eventId} dataInput={clientData?.Liabilities?.['Credit Cards']} onUpdate={onUpdatedData<'Liabilities', 'Credit Cards'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <MortgagesTable title="Mortgages" type={'Liabilities'} subType={'Mortgages'} eventId={eventId} dataInput={clientData?.Liabilities?.['Mortgages']} onUpdate={onUpdatedData<'Liabilities', 'Mortgages'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <LoansTable title="Loans" type={'Liabilities'} subType={'Loans'} eventId={eventId} dataInput={clientData?.Liabilities?.['Loans']} onUpdate={onUpdatedData<'Liabilities', 'Loans'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      {
        !hideTitle(hideEmptyTables, clientData?.Liabilities) &&
        <Box sx={stickyHeading}>
          <Typography variant="h4">Goals</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Retirement, Travel, Wedding, Celebration, College, Private School, New Business, New Home, Home Improvement, Major Purchase, Provide Care, Gift or Donation, Leave Bequest, Other Goal
          </Typography>
        </Box>
      }

      <RetirementGoalTable title="Retirement Goals" type={'Goals'} subType={'Retirement'} eventId={eventId} dataInput={clientData?.Goals?.['Retirement']} onUpdate={onUpdatedData<'Goals', 'Retirement'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <TravelGoalTable title="Travel Goals" type={'Goals'} subType={'Travel'} eventId={eventId} dataInput={clientData?.Goals?.['Travel']} onUpdate={onUpdatedData<'Goals', 'Travel'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <WeddingGoalTable title="Wedding Goals" type={'Goals'} subType={'Wedding'} eventId={eventId} dataInput={clientData?.Goals?.['Wedding']} onUpdate={onUpdatedData<'Goals', 'Wedding'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <CelebrationGoalTable title="Celebration Goals" type={'Goals'} subType={'Celebration'} eventId={eventId} dataInput={clientData?.Goals?.['Celebration']} onUpdate={onUpdatedData<'Goals', 'Celebration'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <CollegeGoalTable title="College Goals" type={'Goals'} subType={'College'} eventId={eventId} dataInput={clientData?.Goals?.['College']} onUpdate={onUpdatedData<'Goals', 'College'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <PrivateSchoolGoalTable title="Private School Goals" type={'Goals'} subType={'Private School'} eventId={eventId} dataInput={clientData?.Goals?.['Private School']} onUpdate={onUpdatedData<'Goals', 'Private School'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <NewBusinessGoalTable title="New Business Goals" type={'Goals'} subType={'New Business'} eventId={eventId} dataInput={clientData?.Goals?.['New Business']} onUpdate={onUpdatedData<'Goals', 'New Business'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <NewHomeGoalTable title="New Home Goals" type={'Goals'} subType={'New Home'} eventId={eventId} dataInput={clientData?.Goals?.['New Home']} onUpdate={onUpdatedData<'Goals', 'New Home'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <HomeImprovementGoalTable title="Home Improvement Goals" type={'Goals'} subType={'Home Improvement'} eventId={eventId} dataInput={clientData?.Goals?.['Home Improvement']} onUpdate={onUpdatedData<'Goals', 'Home Improvement'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <MajorPurchaseGoalTable title="Major Purchase Goals" type={'Goals'} subType={'Major Purchase'} eventId={eventId} dataInput={clientData?.Goals?.['Major Purchase']} onUpdate={onUpdatedData<'Goals', 'Major Purchase'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <ProvideCareGoalTable title="Provide Care Goals" type={'Goals'} subType={'Provide Care'} eventId={eventId} dataInput={clientData?.Goals?.['Provide Care']} onUpdate={onUpdatedData<'Goals', 'Provide Care'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <GiftOrDonationGoalTable title="Gift or Donation Goals" type={'Goals'} subType={'Gift or Donation'} eventId={eventId} dataInput={clientData?.Goals?.['Gift or Donation']} onUpdate={onUpdatedData<'Goals', 'Gift or Donation'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <LeaveBequestGoalTable title="Leave Bequest Goals" type={'Goals'} subType={'Leave Bequest'} eventId={eventId} dataInput={clientData?.Goals?.['Leave Bequest']} onUpdate={onUpdatedData<'Goals', 'Leave Bequest'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />

      <OtherGoalTable title="Other Goals" type={'Goals'} subType={'Other Goal'} eventId={eventId} dataInput={clientData?.Goals?.['Other Goal']} onUpdate={onUpdatedData<'Goals', 'Other Goal'>} hideEmptyTable={hideEmptyTables} foldEmptyTable={foldEmptyTables} readOnly={readOnly} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openErrorSnack}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnack(undefined)}
      >
        <Alert variant='filled' severity='error'>
          {openErrorSnack}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openSuccessSnack}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnack(undefined)}
      >
        <Alert variant='filled' severity='success'>
          {openSuccessSnack}
        </Alert>
      </Snackbar>
    </>
  )
}

const stickyHeading: SxProps = {
  position: 'sticky',
  top: -25,
  backgroundColor: 'white',
  zIndex: 1,
  paddingTop: '20px',
  paddingBottom: "5px",
  paddingLeft: "0px",
  width: '100%',
  overflow: 'hidden',
}