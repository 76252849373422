/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProcessingStatus {
    PROCESSING = 'Processing',
    PROCESSING_QUEUED = 'ProcessingQueued',
    DONE = 'Done',
    DONE_EMPTY_CONTENT = 'DoneEmptyContent',
    ABORTED = 'Aborted',
}
