import React from 'react'
import { Card, Box, SxProps, Typography, Divider, Button, Skeleton, Tooltip, ButtonBase } from '@mui/material'
import { observer } from 'mobx-react-lite'
import SignInCalendarButton from '../../components/calendar/SignInCalendarButton'
import ContainerSmall from 'components/ContainerSmall'
import IntegrationsBackButton from 'components/IntegrationsBackButton'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { GetCalendarUser, deleteCalendarUser } from 'service/calendarApi'
import { isCalendarConnected } from 'utils'
import CancelIcon from '@mui/icons-material/Cancel'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { gColor, gSx } from 'styles/Theme'
import { Media } from 'service/media'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import VideocamIcon from '@mui/icons-material/Videocam'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'

export default observer(CalendarAuthScreen)
function CalendarAuthScreen() {
  const navigate = useNavigate()
  const { isBlocked } = useIsBlockedByPlan()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['GetCalendarUser'],
    queryFn: async () => await GetCalendarUser(),
  })
  const isCalConnected = isCalendarConnected(data)
  const g = data?.connections?.filter(conn => conn.platform === 'google')[0]
  const ms = data?.connections?.filter(conn => conn.platform === 'microsoft')[0]

  async function onDisconnect() {
    await deleteCalendarUser()
    await refetch()
  }

  return (
    <ContainerSmall>
      <IntegrationsBackButton />
      <Typography variant='h3'>Calendar Integration</Typography>
      <Divider sx={{ marginY: '2vh ' }} />

      {
        isLoading
          ? <Skeleton variant="rectangular" width={'100%'} height={50} />
          : (
            isCalConnected ? (
              <>
                {
                  ms && ms.connected && (
                    <>
                      <Box sx={gSx.Row}>
                        <Box
                          component="img"
                          sx={icon}
                          src={Media.microsoft100}
                          alt="Microsoft Logo"
                        />
                        <Typography>Microsoft Outlook Calendar</Typography>
                      </Box>
                      <Message />
                      <Box sx={{ padding: 3 }} />

                      <Typography variant='h5' sx={{ textAlign: 'center', color: gColor.green500 }}>
                        Connected
                      </Typography>

                      <Card>
                        <Box sx={gSx.RowBetween}>
                          <Typography>{ms.email}</Typography>
                          <Tooltip title='Disconnect Calendar'>
                            <ButtonBase
                              onClick={onDisconnect}
                            >
                              <CancelIcon color='disabled' />
                            </ButtonBase>
                          </Tooltip>
                        </Box>

                      </Card>
                    </>
                  )
                }
                {
                  g && g.connected && (
                    <>
                      <Box sx={gSx.Row}>
                        <Box
                          component="img"
                          sx={icon}
                          src={Media.googleCalendar}
                          alt="Google Logo"
                        />
                        <Typography variant='h1'>Google Calendar</Typography>
                      </Box>
                      <Message />
                      <Box sx={{ padding: 3 }} />

                      <Typography variant='h5' sx={{ textAlign: 'center', color: gColor.green500 }}>
                        Connected
                      </Typography>

                      <Card>
                        <Box sx={gSx.RowBetween}>
                          <Typography>{g.email}</Typography>
                          <Tooltip title='Disconnect Calendar'>
                            <ButtonBase
                              onClick={onDisconnect}
                            >
                              <CancelIcon color='disabled' />
                            </ButtonBase>
                          </Tooltip>
                        </Box>

                      </Card>
                    </>
                  )
                }
              </>
            ) : (
              <>
                <Message />
                <Box sx={{ padding: 2 }} />
                <Card sx={sxCard}>
                  <Box sx={sxCenter}>
                    <SignInCalendarButton />
                  </Box>
                </Card>
              </>
            )
          )
      }

      {
        isCalConnected &&
        <Box sx={bottom}>
          <Box sx={{ padding: 2 }} />
          <Button
            aria-label='Calendar Events'
            onClick={() => navigate(Path.Calendar)}
            startIcon={<CalendarMonthIcon />}
            variant='contained'
          >
            Calendar Events
          </Button>
          <Box sx={{ padding: 1 }} />
          <Button
            aria-label='Recorder Settings'
            onClick={() => navigate(Path.RecorderSettings)}
            startIcon={<VideocamIcon />}
            variant='contained'
          >
            Recorder Settings
          </Button>
        </Box>
      }

    </ContainerSmall >
  )
}

function Message() {
  return (
    <>
      <Box sx={{ padding: 2 }} />
      <Typography variant='body2'>
        By Connecting your calendar, FinMate AI can automatically record your upcoming calls.
      </Typography>
      <Typography variant='body2'>
        Finmate AI requests read-only  access to your calendar.
      </Typography>
    </>
  )
}

const bottom: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const icon: SxProps = {
  width: 40,
  marginRight: 2
}

const sxCard: SxProps = {
  padding: '2vh'
}

const sxCenter: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  margin: '3vh'
}
