import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControl, MenuItem, Select, SxProps, Typography } from '@mui/material'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { gSx } from 'styles/Theme'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUserConfig, setLanguage } from 'service'
import { Language } from 'client'

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()

  const [err, setErr] = useState<string | undefined>()

  async function onChange(event: any) {
    setErr(undefined)
    const newLang = event.target.value as Language
    const res = await setLanguage(newLang)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      i18n.changeLanguage(newLang)
      await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
    }
  }

  return (
    <Box>
      <FormControl sx={{ minWidth: 150 }}>
        <Select
          size='small'
          labelId="language-label"
          id="language"
          value={i18n.language}
          onChange={onChange}
        >
          <MenuItem value={Language.EN}>
            <Box sx={gSx.Row}>
              <Box sx={sxFlag}>{getUnicodeFlagIcon('US')}</Box>
              <Typography>English</Typography>
            </Box>
          </MenuItem>

          <MenuItem value={Language.FR_CA}>
            <Box sx={gSx.Row}>
              <Box sx={sxFlag}>{getUnicodeFlagIcon('CA')}</Box>
              <Typography>Français Canadien</Typography>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
      {err && <Typography color={'tomato'}>{err}</Typography>}
    </Box>
  )
}

export default LanguageSelector

const sxFlag: SxProps = {
  fontSize: '1rem',
  marginRight: 1
}