import React, { useState } from 'react'
import { Typography, Tab, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import 'firebase/auth'
import { EventBase } from 'client'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import { gColor, gSx } from 'styles/Theme'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RedtailWorkflowsCreate from './RedtailWorkflowsCreate'

interface Props {
  event: EventBase
}

export default function RedtailWorkflowsCard({ event }: Props) {
  const isSent = !!event.redtail_workflows_sent
  const [expanded, setExpanded] = useState(!isSent)

  return (
    <Accordion
      sx={gSx.Crm.Accordian}
      expanded={expanded}
      onChange={() => setExpanded(state => !state)}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={gSx.Crm.AccordianSummary}
      >
        <Box sx={gSx.Row}>
          {isSent
            ? <CheckCircleIcon sx={{ ...gSx.Crm.LeftIcon, color: gColor.green500 }} />
            : <AccountTreeOutlinedIcon sx={gSx.Crm.LeftIcon} />
          }
          <Typography variant='h5'>Workflows</Typography>

        </Box>
        {isSent &&
          <Typography color={gColor.green500} sx={{ paddingRight: 2 }}>Sent</Typography>
        }
      </AccordionSummary>

      <AccordionDetails>

        <RedtailWorkflowsCreate
          event={event}
          setExpanded={setExpanded}
        />

      </AccordionDetails>
    </Accordion >

  )
}