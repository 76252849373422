import React, { useEffect, useMemo, useRef, useState } from 'react'

import { MRT_EditActionButtons, type MRT_ColumnDef, type MRT_Row, type MRT_TableOptions, useMaterialReactTable } from 'material-react-table'

import DeleteIcon from '@mui/icons-material/Delete'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material'

import TableAccordion from './TableAccordion'
import { getNumberCell } from './CustomCells'
import { Mortgage } from '../../client'
import { foldTable, hideTable, FinanceTableProps } from './financesUtils'

export default function MortgagesTable({ eventId, title, type, subType, dataInput, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: FinanceTableProps<'Liabilities', 'Mortgages'>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<Mortgage[]>([])

  const [newData, setNewData] = useState<Mortgage>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput)
    }
  }, [dataInput])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const columns = useMemo<MRT_ColumnDef<Mortgage>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        minSize: 250,
        size: 250
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        editSelectOptions: ["ARM", "Fixed", "Int Only"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Type': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Type'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Start Date',
        header: 'Start date',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Start date': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Start date'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'End Date',
        header: 'End date',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'End date': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['End date'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Interest Rate',
        header: 'Interest Rate',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Interest Rate': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Interest Rate'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell({ style: 'percent' }),
        size: 50
      },
      {
        accessorKey: 'Payment',
        header: 'Monthly Payment',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Payment': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Payment'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Original Balance',
        header: 'Original Balance',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Original Balance': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Original Balance'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Current Balance',
        header: 'Current Balance',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Current Balance': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Current Balance'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },

    ],
    [dataList, newData],
  )

  const handleCreate: MRT_TableOptions<Mortgage>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...dataList]
    if (newData) {
      updated.push(newData)
      onUpdate(type, subType, updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<Mortgage>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, subType, updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: dataList,
    editDisplayMode: 'cell',
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: !readOnly,
    enableRowActions: !readOnly,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewData({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New {title}</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
