import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, SxProps, Typography } from '@mui/material'
import { authPreciseFp } from 'service/integrations/precisefpApi'
import { Media } from 'service/media'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import { gSx } from 'styles/Theme'
import { PreciseFpConfigBase } from 'client'
import { getErrorMessage } from "utils"

export default function AuthPreciseFpCallback() {
  const url = window.location.href

  const [err, setErr] = useState<string | undefined>()
  const [res, setRes] = useState<PreciseFpConfigBase | undefined>()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    const error = params.get('error')

    if (code) {
      const auth = async () => {
        try {
          const res = await authPreciseFp(code)
          setRes(res)
        } catch (e) {
          const err = getErrorMessage(e)
          setErr(err.message)
        }
      }
      auth()
    }
  }, [])

  if (err) {
    <Box sx={gSx.CenterPage}>
      <Box sx={gSx.Row}>
        <Box component='img' sx={sxIcon} src={Media.logo100} />
        <SyncAltIcon />
        <Box component='img' sx={sxIcon} src={Media.precisefpIcon} alt="PreciseFP" />
      </Box>
      <Typography variant='h3'>Something went wrong</Typography>
      <Typography sx={{ color: 'tomato' }}>{err}</Typography>
    </Box>
  }

  return (
    <Box sx={gSx.CenterPage}>
      <Box sx={gSx.Row}>
        <Box component='img' sx={sxIcon} src={Media.logo100} />
        <SyncAltIcon />
        <Box component='img' sx={sxIcon} src={Media.precisefpIcon} alt="PreciseFP" />
      </Box>

      {res
        ?
        <>
          <Typography variant='h3'>You are signed into PreciseFP</Typography>
          <Typography variant='h4'>You may close this window</Typography>
        </>
        :
        <>
          <Typography variant='h3'>Finalizing PreciseFP Connections</Typography>
          <Box sx={{ padding: '10px' }} />
          <CircularProgress />
        </>
      }
    </Box>
  )
}

const sxIcon: SxProps = {
  height: '50px',
  margin: 3
}
