import React from 'react'
import { SxProps, Box } from '@mui/material'
import { EventBase } from '../../client'
import EventTimeline from './EventTimeline'
import LongestMonologue from './LongestMonologue'
import SentimentCard from './SentimentCard'

interface Props {
  event: EventBase
  disabled: boolean
}

export default function EventAnalysis({ event, disabled }: Props) {

  return (
    <Box sx={sxCard}>
      <SentimentCard event={event} disabled={disabled} />
      <EventTimeline event={event} />
      <LongestMonologue event={event} />
    </Box>
  )
}

const sxCard: SxProps = {
  overflowY: 'auto',
  overflowX: 'hidden',
}
