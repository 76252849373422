import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// the translations
// https://react.i18next.com/guides/multiple-translation-files)
import enTranslations from "./locales/en/common.json"
import frCaTranslations from "./locales/fr-CA/common.json"

// see i18nInit.tsx for manual language detection.

i18n
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'fr-CA'],
    ns: ['common'],
    defaultNS: 'common',
    resources: {
      en: {
        common: enTranslations,
      },
      "fr-CA": {
        common: frCaTranslations,
      },
    },
    fallbackLng: {
      fr: ['fr-CA'], // French defaults to French Canadian
      default: ['en'], // All other fallbacks go to English
    },
    // saveMissing: true, // send not translated keys to endpoint
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    returnObjects: true, // Ensure objects return properly (useful for nested translations)
    returnNull: false,  // Fallback to key if value is null
    parseMissingKeyHandler: (key) => key, // Fallback to the key if value is missing or empty
    // debug: true,
  })

export default i18n
