import { ClientData } from '../../client'

export interface FinanceTableProps<T extends keyof ClientData, S extends keyof NonNullable<ClientData[T]>> {
  eventId?: string
  title: string
  type: T
  subType: S
  dataInput?: NonNullable<ClientData[T]>[S]
  foldEmptyTable: boolean
  hideEmptyTable: boolean
  readOnly: boolean
  onUpdate: (type: T, subType: S, updated: NonNullable<ClientData[T]>[S]) => void
}

export function hideTable(hideEmptyTable: boolean, data: any[] | undefined) {
  if (hideEmptyTable && (!data || data.length == 0))
    return true
  return false
}

export function hideTitle(hideEmptyTables: boolean, data: any | undefined) {
  if (hideEmptyTables) {
    if (!data) return true
    for (const [key, value] of Object.entries(data)) {
      console.log(key, (value && Array.isArray(value) && value.length > 0), value)
      if (value && Array.isArray(value) && value.length > 0) return false
    }
    return true
  }
  return false
}

export function foldTable(foldEmptyTable: boolean, data: any[] | undefined) {
  if (foldEmptyTable && (!data || data.length == 0))
    return false
  return true
}
