import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  SxProps,
  Typography,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Divider,
  MenuList,
  MenuItem,
  Skeleton,
} from '@mui/material'
import { Path } from '../../routes'
import { auth } from '../../service/api'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { capitalizeEveryWord, isImageURLReal } from '../../utils'
import { signOut } from 'firebase/auth'
import LogoutIcon from '@mui/icons-material/Logout'
import { OrgBase, OrgUserRole, UserUsage } from '../../client'
import HoursRemaining from 'components/help/HoursRemaining'
import { getOrgUser } from 'service/orgsApi'

interface Props {
  drawerWidth: number
  org?: OrgBase
  usage?: UserUsage
}

export default function NavBottomAccount({ org, usage, drawerWidth }: Props) {
  const navigate = useNavigate()
  const fbUser = auth.currentUser
  const photoUrl = fbUser?.photoURL
  const orgUser = getOrgUser(org, fbUser?.uid)

  useEffect(() => {
    async function checkImage() {
      setReal(photoUrl ? await isImageURLReal(photoUrl) : false)
    }
    checkImage()
  }, [])

  const [isReal, setReal] = useState(false)

  const accountButtonRef = useRef(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const accountPopoverClick = () => {
    setPopoverOpen(true)
  }

  const popoverId = popoverOpen ? 'simple-popover' : undefined

  const sxFooter: SxProps = {
    '&:hover': {
      backgroundColor: 'grey.300',
    },
  }

  async function onSignout() {
    await signOut(auth)
    navigate(Path.SignIn)
  }

  if (!fbUser) return (
    <Button
      sx={sxSignUp}
      variant="contained"
      onClick={() => navigate(Path.SignUp)}
    >
      SignUp
    </Button>
  )

  return (
    <>
      <ListItemButton
        sx={sxFooter}
        ref={accountButtonRef}
        onClick={() => {
          accountPopoverClick()
        }}
      >
        <ListItemIcon>
          {isReal && photoUrl ? (
            <img style={avatar} src={photoUrl} alt={'alt'} />
          ) : (
            <AccountCircleIcon />
          )}
        </ListItemIcon>
        <Box>

          <Typography sx={{ fontWeight: 'bold' }}>
            {capitalizeEveryWord(fbUser?.displayName ?? '')}
          </Typography>

          {!org
            ? <Skeleton variant="rectangular" width={'100%'} height={20} />
            : (
              orgUser?.org_user_role == OrgUserRole.INACTIVE
                ? <ListItemText primary={OrgUserRole.INACTIVE} />
                : <ListItemText primary={orgUser?.org_user_plan ? `${orgUser?.org_user_plan} Plan` : ''} />
            )}

        </Box>
      </ListItemButton>

      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={accountButtonRef?.current}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={sxProfile}>
          <Typography variant='h6'>Account</Typography>
          <Typography variant='body2'>{fbUser?.email}</Typography>
        </Box>
        <Divider />

        <HoursRemaining org={org} usage={usage} />

        <MenuList
          disablePadding
          dense
          sx={sxMenuList}
        >
          <MenuItem onClick={onSignout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>{' '}
            Sign out
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  )
}

const sxProfile: SxProps = {
  py: 1.5,
  px: 2,
  backgroundColor: 'white'
}

const sxMenuList: SxProps = {
  p: '8px',
  '& > *': {
    borderRadius: 1,
  },
  backgroundColor: 'white'
}

const avatar = {
  width: '35px',
  height: 'auto',
  borderRadius: '10px'
}

const sxSignUp: SxProps = {
  marginY: '2vh',
  marginLeft: '50px',
  position: 'fixed',
  bottom: 0,
}