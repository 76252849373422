import React from 'react'
import { Box, SxProps, Typography, Avatar, Tooltip } from '@mui/material'
import { ClientDetail, ClientIndex } from 'client'
import { CrmClientBadge } from './CrmClientBadge'
import { grey } from '@mui/material/colors'
import { getInitial } from 'utils'
import { gSx } from 'styles/Theme'
import { getContactName } from 'service/clientApi'

interface Props {
  contact?: ClientIndex | ClientDetail
}

export function ContactChip({ contact }: Props) {

  if (!contact)
    return

  const name = getContactName(contact)

  return (
    <Box sx={gSx.Crm.Chip()}>
      {contact.photo_url
        ? <Avatar alt={name} src={contact.photo_url} sx={sxAvatar} />
        : <Avatar sx={sxAvatar}>{getInitial(name)}</Avatar>
      }
      <Tooltip title={contact.email}>
        <Typography>{name}</Typography>
      </Tooltip>
      <CrmClientBadge client={contact} />
    </Box>
  )
}

const sxAvatar: SxProps = {
  width: 25, height: 25,
  fontSize: 12, marginRight: 0.5,
  color: grey[700],
  fontWeight: 'bold',
}
