import React, { useEffect, useRef, useState } from 'react'
import { Box, Card, Typography, Link, List, ListItem, Pagination, Button, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getEvents } from '../../service/eventApi'
import { useQuery } from '@tanstack/react-query'
import Grid from '@mui/material/Unstable_Grid2'
import EventListItem from './EventListItem'
import { useStore } from '../../hooks/useStore'
import SearchEventBar from './SearchEventBar'
import PaginationTitle from './PaginationTitle'
import { logInfo } from 'log'
import { EventSimpleBase, ListEvents } from 'client'
import { ExistingEventsSkeleton } from 'components/Skeletons'
import { gColor, gSx } from 'styles/Theme'

import CheckboxTriState, { CheckboxTriType } from 'components/CheckBoxTriState'
import DeleteMultipleEvents from './DeleteMultipleEvents'
import { grey } from '@mui/material/colors'
import EventsListTopRow from './EventsListTopRow'

export default observer(MyEventsList)
function MyEventsList() {
  const store = useStore()
  const params = store.User.getEventSearchParams()
  const [page, setPage] = useState(1)
  const res = useRef<ListEvents | undefined>(undefined)

  const [checkedEventIds, setCheckedEventIds] = useState<string[]>([])
  const [checkBoxAll, setCheckedBoxAll] = useState<CheckboxTriType>('clear')

  const { data, isLoading } = useQuery({
    queryKey: ['getEvents', JSON.stringify(params), page],
    queryFn: async () => await getEvents(params, page, res.current),
  })

  useEffect(() => {
    res.current = data
  }, [data])

  const pageRange = data?.pages?.[0]

  const maxPages = Math.ceil((data?.total ?? 0) / (data?.page_limit ?? 1))

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    logInfo('Event List, date search, page button pressed', { page: value })
    setPage(value)
  }

  function getChecked(e: EventSimpleBase) {
    return checkedEventIds.some(c => c == e.id)
  }

  function setChecked(e: EventSimpleBase, checked: boolean) {
    if (checked) {
      setCheckedEventIds([...checkedEventIds, e.id])
    } else {
      const updatedArr = checkedEventIds.filter(eventId => eventId !== e.id)
      setCheckedEventIds(updatedArr)
    }
  }

  async function resetParams() {
    store.User.setEventSearchParams({ ...params, earliest: undefined, latest: undefined })
    setPage(1)
  }

  useEffect(() => {
    const total = data?.results?.length ?? 1
    if (checkedEventIds.length == 0)
      setCheckedBoxAll('clear')
    else if (checkedEventIds.length == total) {
      setCheckedBoxAll('filled')
    } else {
      setCheckedBoxAll('partial')
    }
  }, [checkedEventIds])

  function onCheckBoxAll() {
    if (!data?.results)
      return

    if (checkBoxAll == 'clear') {
      const all: string[] = []
      for (const a of data.results) {
        all.push(a.id)
        setCheckedEventIds(all)
        setCheckedBoxAll('filled')
      }
    } else {
      setCheckedEventIds([])
      setCheckedBoxAll('clear')
    }
  }

  return (
    <Card>

      <Grid container sx={{ marginBottom: 2 }}>
        <Grid xs={12} md={6} >
          <SearchEventBar setPage={setPage} />
        </Grid>
        <Grid xs={12} md={6} >
          <DeleteMultipleEvents
            checkedEventIds={checkedEventIds}
            setCheckedEventIds={setCheckedEventIds}
            resetParams={resetParams}
          />
        </Grid>
      </Grid>

      <EventsListTopRow
        data={data}
        checkedEventIds={checkedEventIds}
        setCheckedEventIds={setCheckedEventIds}
        isMyMeetings
      />

      {
        isLoading ? (
          <ExistingEventsSkeleton />
        ) : (
          data?.results?.length != undefined && data?.results?.length > 0 ? (
            <>
              <List disablePadding={true} dense={true} >
                {data.results.map((d) => (
                  <ListItem key={d?.id} sx={{ width: '100%', padding: 0 }}>
                    <EventListItem data={d} key={d.id} checked={getChecked(d)} onChecked={(checked) => setChecked(d, checked)} />
                  </ListItem>
                ))}
              </List>

              {params.title
                ? <PaginationTitle page={page} setPage={setPage} pageRange={pageRange} />
                : <Pagination count={maxPages} page={page} onChange={handleChange} />
              }

            </>
          ) : (
            <Box sx={{ m: 5 }}>
              <Typography >No existing meetings found</Typography>

              {params.title &&
                <PaginationTitle page={page} setPage={setPage} pageRange={pageRange} />
              }
            </Box>
          )
        )
      }

    </Card >
  )
}
