import React, { useState } from 'react'
import { Box, Button, Tooltip } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import UploadDialog from 'components/recorder/UploadDialog'
import { useTranslation } from 'react-i18next'

export function UploadButton() {
  const { t } = useTranslation()
  const [openUploadDialog, setOpenDialog] = useState(false)
  const { isBlocked, isLoading } = useIsBlockedByPlan()

  return (
    <>
      <Tooltip title={isBlocked ? 'Requires Starter or Standard Plan' : ""}>
        <Box>
          <Button
            variant='contained'
            startIcon={<CloudUploadIcon />}
            onClick={() => setOpenDialog(true)}
            disabled={isBlocked || isLoading}
            aria-label='upload meeting'
            size='large'
            sx={{ width: 200 }}
          >
            {t('Upload')}
          </Button>
        </Box>
      </Tooltip>

      <UploadDialog
        open={openUploadDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
      />
    </>
  )
}
