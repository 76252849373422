import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Box, IconButton, Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { BusinessAsset } from '../../client'
import DeleteIcon from '@mui/icons-material/Delete'
import { hideTable, foldTable } from './financesUtils'
import TableAccordion from './TableAccordion'
import { getNumberCell } from './CustomCells'

interface Props {
  eventId?: string
  dataListInput?: BusinessAsset[]
  foldEmptyTable: boolean
  hideEmptyTable: boolean
  readOnly: boolean
  onUpdate: (updated: BusinessAsset[]) => (void)
}

export default function BusinessAssetTable({ eventId, dataListInput, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: Props) {

  const [expanded, setExpanded] = useState(true)
  const [businessAssetList, setBusinessAssetList] = useState<BusinessAsset[]>([])
  const [newBusinessAsset, setNewBusinessAsset] = useState<BusinessAsset>()

  useEffect(() => {
    if (dataListInput) {
      console.log("Business Asset", dataListInput)
      setBusinessAssetList(dataListInput)
    }
  }, [dataListInput])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, businessAssetList))
  }, [foldEmptyTable, businessAssetList])

  const columns = useMemo<MRT_ColumnDef<BusinessAsset>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBusinessAsset((prevNew) => ({
                ...prevNew,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...businessAssetList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBusinessAsset((prevNew) => ({
                ...prevNew,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...businessAssetList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Current Value',
        header: 'Current Value',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBusinessAsset((prevNew) => ({
                ...prevNew,
                'Current Value': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...businessAssetList]
            updated[rowIdx]['Current Value'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      { // TODO: check year
        accessorKey: 'Planned Year of Sale',
        header: 'Planned Year of Sale',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewBusinessAsset((prevNew) => ({
                ...prevNew,
                'Planned Year of Sale': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...businessAssetList]
            updated[rowIdx]['Planned Year of Sale'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [businessAssetList, newBusinessAsset],
  )

  const handleCreate: MRT_TableOptions<BusinessAsset>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...businessAssetList]
    if (newBusinessAsset) {
      updated.push(newBusinessAsset)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<BusinessAsset>) {
    const updated = [...businessAssetList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: businessAssetList,
    editDisplayMode: 'cell',
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: !readOnly,
    enableRowActions: !readOnly,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewBusinessAsset({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New Business Asset</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  if (hideTable(hideEmptyTable, businessAssetList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title="Business Assets" table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
