import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Button, Skeleton, Divider, Container, CardHeader } from '@mui/material'
import 'firebase/auth'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import { gSx } from '../../styles/Theme'

import { Media } from 'service/media'
import { logInfo } from 'log'
import { LoadingButton } from '@mui/lab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Path } from 'routes'
import { useNavigate } from 'react-router-dom'
import ContainerSmall from 'components/ContainerSmall'
import ScrollToAnchor from 'components/ScrollToAnchor'
import { getPreciseFpConfig, disconnectPrecisefp, precisefpOAuth } from 'service/integrations/precisefpApi'
import { retryStopOnlyNotFound } from 'hooks/hookHelpers'

export default function PrecisefpSettings() {
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const queryClient = useQueryClient()

  useEffect(() => {
    logInfo('Precisefp Settings Screen')
  }, [])

  const { data, isLoading } = useQuery({
    queryKey: ['getPreciseFpConfig'], queryFn: getPreciseFpConfig,
    retry: retryStopOnlyNotFound
  })

  async function onSignIn() {
    setBusy(true)
    logInfo('PreciseFp Sign In Pressed')
    await precisefpOAuth()
    setBusy(false)
  }

  async function signout() {
    logInfo('Precisefp Signout Pressed')
    setErr(undefined)
    setBusy(true)
    const res = await disconnectPrecisefp()
    if (res instanceof Error) {
      setErr(res.message)
    }
    else {
      queryClient.resetQueries({ queryKey: ['getPreciseFpConfig'] })
    }
    setBusy(false)
  }

  return (
    <ContainerSmall>
      <Button
        onClick={() => navigate(Path.Integrations)}
        startIcon={<ArrowBackIcon />}
      >
        Integrations
      </Button>

      <Typography variant='h3'>PreciseFP Integration</Typography>
      <Divider sx={{ marginY: '2vh ' }} />

      <Link href="https://precisefp.com" rel='noopener' target='_blank'>
        <Box
          component="img"
          sx={icon}
          src={Media.precisefpLogo}
          alt="PreciseFP"
        />
      </Link>

      <Box sx={{ padding: '1vh ' }} />

      <Typography>Connect FinMate AI to PreciseFP, to easily keep your records up to date.</Typography>
      <Box sx={{ padding: '1vh ' }} />
      <Typography>Transfer your AI generated financial data to PreciseFP.</Typography>

      <Box sx={{ padding: '1vh ' }} />

      <Card>

        {isLoading ? (
          <Skeleton variant="rectangular" width={'100%'} height={100} />
        ) : (
          data ? (
            <Box sx={gSx.RowBetween}>
              <Typography>Signed In as {data?.user?.first_name} {data?.user?.last_name}, {data?.user?.email}</Typography>

              <Tooltip title="Disconnect PreciseFP">
                <LoadingButton
                  aria-label='Disconnect PreciseFP'
                  onClick={signout}
                  disabled={busy}
                  loading={busy}
                >
                  <CloseIcon />
                </LoadingButton>
              </Tooltip>
            </Box>
          ) : (
            <Box sx={sxConnect}>
              <LoadingButton
                variant='contained' onClick={onSignIn}
                loading={busy}
                disabled={busy}
                sx={{ width: 150 }}
              >
                Connect
              </LoadingButton>
            </Box>
          )
        )
        }
        {err && <Typography sx={{ color: 'tomato' }}>
          {err}
        </Typography>
        }
      </Card >

      {/* <Button target="_blank" rel="noopener"
        href="https://docs.finmate.ai/docs/category/precisefp"
        startIcon={<TextSnippetIcon />}
      >
        PreciseFP Docs
      </Button> */}

      <ScrollToAnchor />
    </ContainerSmall >
  )
}

const icon: SxProps = {
  width: 200,
  marginRight: 2
}

const sxConnect: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}