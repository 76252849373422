import React, { useEffect, useRef, useState } from 'react'
import { SxProps, Card, Typography, Box, Skeleton } from '@mui/material'
import { EventBase, SentimentDoc } from '../../client'
import { getEventTranscript, getSentimentAnalysis, runSentimentAnalysis } from 'service/eventApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { LoadingButton } from '@mui/lab'
import { getSpeakerIdNameMap, getSpeakerSentiment } from 'utils/eventAnalysis'
import { gSx } from 'styles/Theme'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryCursorContainer } from 'victory'
import { isObjectEmpty } from 'utils'
import { auth } from 'service/api'
import { useStore } from 'hooks/useStore'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'

interface Props {
  event: EventBase
  disabled: boolean
}

export default function SentimentCard({ event, disabled: disabledByParent }: Props) {
  const store = useStore()
  const queryClient = useQueryClient()

  const { data: transcript } = useQuery({
    queryKey: ['getEventTranscript', event.id],
    queryFn: async () => await getEventTranscript(event.id),
    enabled: queryClient.getQueryData(['getEventTranscript', event.id]) === undefined,
  })

  const speakerMap = getSpeakerIdNameMap(transcript?.entries)
  const [busy, setBusy] = useState(false)
  const [raw, setRaw] = useState<SentimentDoc | undefined>()
  const [width, setWidth] = useState(window.innerWidth)
  const [cursorValue, setCursorValue] = useState(0)
  const chartRef = useRef<any>(null)
  const hasTranscript = transcript?.entries && transcript?.entries?.length > 0
  const { isBlocked } = useIsBlockedByPlan(true)
  const disabled = disabledByParent || isBlocked || !hasTranscript

  const { data, isLoading } = useQuery({
    queryKey: ['getSentimentAnalysis' + `${event?.id ?? ''}`],
    queryFn: async () => {
      const res = await getSentimentAnalysis(event?.id)
      setRaw(res)
      return res ?? true
    },
  })
  const speakers = getSpeakerSentiment(raw, speakerMap)
  // TODO get sentiment based on magnitude. the sentiment data is score and magnitude.

  const updateWidth = (ev: any) => {
    setWidth(ev.target.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  const handleCursorChange = (value: number) => {
    setCursorValue(value)
  }

  const handleMouseDown = () => {
    if (chartRef.current) {
      store.User.setSeekSeconds(cursorValue)
      store.User.setTranscriptSeekSeconds(cursorValue)
    }
  }

  async function onClick() {
    setBusy(true)
    if (event?.id) {
      const res = await runSentimentAnalysis(event?.id)
      setRaw(res)
    }
    setBusy(false)
  }

  return (
    <Card sx={sxCard}>
      <Box sx={gSx.Row}>
        <Typography variant='h4'>Sentiment Analysis</Typography>
      </Box>
      <Box sx={{ padding: 1 }} />

      {isLoading || !event ? (
        <Skeleton variant="rectangular" width={'100%'} height={100} />
      ) : (
        !raw || isObjectEmpty(speakers) ? (
          <Box>
            <LoadingButton
              variant='contained'
              onClick={onClick}
              loading={busy}
              disabled={disabled}
            >
              Analyze Sentiment
            </LoadingButton>
          </Box>
        ) : (
          <>
            <Typography>The <span style={{ fontStyle: 'italic' }}>Sentiment</span> indicates the positive and negative emotion. {'Key moments are areas with extreme values.'}</Typography>

            <Typography variant='caption'>Click on the graph for transcript location</Typography>

            <Box sx={{ padding: 2 }} />

            {Object.entries(speakers).map(([speaker, entries]) => {
              const positiveData = entries.score.map(point => ({
                ...point,
                y: point.y > 0 ? point.y : 0 // Only include positive values
              }))

              const negativeData = entries.score.map(point => ({
                ...point,
                y: point.y < 0 ? point.y : 0 // Only include negative values
              }))

              return (
                <Box key={speaker}>
                  <Box sx={gSx.RowBetween}>
                    <Typography sx={sxText}>{speaker}</Typography>
                  </Box>
                  <div ref={chartRef} onMouseDown={handleMouseDown}>
                    <VictoryChart
                      domainPadding={0}
                      padding={{ top: 0, bottom: 50, left: 80, right: 0 }}
                      height={250}
                      width={width}
                      containerComponent={
                        <VictoryCursorContainer cursorDimension='x' onCursorChange={handleCursorChange} />
                      }
                    >
                      <VictoryAxis tickFormat={() => ''} />
                      <VictoryAxis dependentAxis
                        label="Sentiment"
                        style={{
                          axisLabel: { padding: 40, fontSize: 30 },
                        }}
                      />
                      <VictoryArea
                        data={positiveData}
                        x='x'
                        y='y'
                        style={{
                          data: { fill: "#349EAD", strokeWidth: 0 },
                        }}
                      />
                      <VictoryArea
                        data={negativeData}
                        x='x'
                        y='y'
                        style={{
                          data: { fill: "tomato", strokeWidth: 0 },
                        }}
                      />
                    </VictoryChart>
                  </div>
                </Box>
              )
            })}
          </>
        )
      )}
    </Card>
  )
}

const sxCard: SxProps = {
  overflowY: 'auto',
  overflowX: 'hidden',
}

const sxText: SxProps = {
  color: '#1976d2',
  fontWeight: 'bold',
}
