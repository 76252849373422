import React, { useEffect, useState } from 'react'
import { Box, SxProps } from '@mui/material'
import { Media } from 'service/media'
import { SalesforceApp } from 'client'
import LinkIcon from '@mui/icons-material/Link'
import { gSx } from 'styles/Theme'

export default function SalesforceFinmateConnectIcons() {
  const [app, setApp] = useState<SalesforceApp | undefined>()
  const storageItem = localStorage.getItem('SalesforceApp')

  useEffect(() => {
    if (storageItem != null)
      setApp(storageItem as SalesforceApp)
  }, [storageItem])

  if (app == undefined)
    return null

  if (app == SalesforceApp.PRACTIFI)
    return (
      <Box sx={gSx.Row}>
        <Box component='img' sx={sxIcon} src={Media.logo100} />
        <LinkIcon />
        <Box component='img' sx={sxIcon} src={Media.practifiIcon} />
      </Box>
    )

  if (app == SalesforceApp.XLR8)
    return (
      <Box sx={gSx.Row}>
        <Box component='img' sx={sxIcon} src={Media.logo100} />
        <LinkIcon />
        <Box component='img' sx={sxIcon} src={Media.xlr8Icon} />
      </Box>
    )

  return (
    <Box sx={gSx.Row}>
      <Box component='img' sx={sxIcon} src={Media.logo100} />
      <LinkIcon />
      <Box component='img' sx={sxIcon} src={Media.salesforceIcon} />
    </Box>
  )
}

const sxIcon: SxProps = {
  height: '50px',
  margin: 3
}
