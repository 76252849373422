import React, { useEffect, useState } from 'react'
import { Typography, SxProps, Box, Card, TextField, Snackbar, Alert, Button, Dialog, IconButton, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../service/api'
import { ClientDetail } from '../../client'
import { sleep } from '../../utils'
import { Path } from '../../routes'
import { logErr, logInfo } from '../../log'
import { upsertClient } from 'service/clientApi'
import { LoadingButton } from '@mui/lab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { gSx } from '../../styles/Theme'
import { useQueryClient } from '@tanstack/react-query'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import CloseIcon from '@mui/icons-material/Close'

export default function AddClientDialog() {
  const navigate = useNavigate()
  const fbUser = auth.currentUser
  const queryClient = useQueryClient()

  const [showDialog, setDialog] = useState(false)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [successSnack, setSuccessSnack] = useState(false)

  useEffect(() => {
    if (showDialog) {
      logInfo('Add Client Start Pressed')
      setFirstname('')
      setLastname('')
      setEmail('')
    }

  }, [showDialog])

  async function onSave() {
    setBusy(true)
    setErr(undefined)

    const clientData: ClientDetail = {
      email: email,
      owner_id: fbUser?.uid ?? '',
      first_name: firstname,
      last_name: lastname,
    }
    const res = await upsertClient(clientData)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSuccessSnack(true)
      queryClient.invalidateQueries({ queryKey: ['getClientsIndex'] })
      setDialog(false)
    }
    setBusy(false)

  }

  return (
    <>
      <Button
        variant='contained'
        aria-label="add a contact"
        onClick={() => setDialog(true)}
        startIcon={<PersonAddIcon />}
      >
        Add a Contact
      </Button>

      <Dialog open={showDialog} onClose={() => setDialog(false)}>

        <DialogTitle>Add a New Contact</DialogTitle>

        <DialogContent>
          <Box sx={{ padding: 1 }} />
          <TextField
            autoFocus
            label='First Name'
            variant="outlined"
            value={firstname}
            onChange={(t) => setFirstname(t.target.value)}
            sx={sxText}
          />

          <Box sx={{ padding: '1vh' }} />

          <TextField
            label='Last Name'
            variant="outlined"
            value={lastname}
            onChange={(t) => setLastname(t.target.value)}
            sx={sxText}
          />

          <Box sx={{ padding: '1vh' }} />

          <TextField
            label='Email'
            variant="outlined"
            value={email}
            onChange={(t) => setEmail(t.target.value)}
            sx={sxText}
            required={true}
            helperText="required"
          />

          <Typography sx={{ paddingTop: 1, color: 'tomato' }}>
            {err}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setDialog(false)}>
            Cancel
          </Button>

          <LoadingButton
            variant='contained'
            onClick={onSave}
            sx={sxButton}
            disabled={busy}
            loading={busy}
          >
            Save
          </LoadingButton>
        </DialogActions>

      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successSnack}
        autoHideDuration={3000}
        onClose={() => setSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Client Successfully Added
        </Alert>

      </Snackbar>
    </>
  )
}

const sxButton: SxProps = {
  width: '120px'
}

const sxText: SxProps = {
  width: '45vh'
}

const sxClose: SxProps = {
  alignSelf: 'flex-start',
}
