import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUserConfig } from 'service'
import { Language } from 'client'

/**
 * i18n initialize
 * 
 * 'i18next-browser-languagedetector' has a broken language detection.
 * fails on "en-US" and "en-CA" browser preferred language.
 * in i18n.ts file, the "fallbackLng" and "detection" keys some cases work, but not all.
 * 
 * Below code manually does initialization.
 */

const I18nInit = () => {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()

  const { data: userConfig } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
    enabled: queryClient.getQueryData(['getUserConfig']) === undefined,
  })

  useEffect(() => {
    if (userConfig?.language)
      i18n.changeLanguage(userConfig?.language)
    else if (navigator.language) {
      const browserLang = navigator.language
      if (browserLang == 'fr' || browserLang == 'fr-CA')
        i18n.changeLanguage(Language.FR_CA)
      else
        i18n.changeLanguage(Language.EN)
    }
    else
      i18n.changeLanguage(Language.EN)
  }, [userConfig])

  useEffect(() => {
    // for debugging
    // console.log('Current language:', i18n.language)
    // console.log("Browser preferred languages:", navigator.language)
    // console.log('Loaded resources:', i18n.getResourceBundle(i18n.language, 'common'))
  }, [i18n.language, navigator.languages])

  return null
}

export default I18nInit
