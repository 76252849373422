interface NumberFormatterOptions {
  style?: 'decimal' | 'currency' | 'percent';
  currency?: string; // default USD
  locale?: string; // Default to 'en-US'
  fallbackValue?: string | number | undefined; // Fallback for invalid or undefined values
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}
// TODO: apply locale and currency from pfp account setting
export const getNumberCell = (options: NumberFormatterOptions = {}) => {
  const {
    style = 'currency',
    currency = 'USD',
    locale = 'en-US',
    fallbackValue = undefined,
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
  } = options
  // eslint-disable-next-line react/prop-types
  return ({ cell }: any) => {
    let value = cell.getValue()
    if (value === undefined || value === null) return fallbackValue
    if (style == "percent") {
      value = value / 100.0
    }
    const valueString = (new Intl.NumberFormat(locale, { style, currency, minimumFractionDigits, maximumFractionDigits })).format(value)
    try {
      return (
        <>
          {valueString}
        </>
      )
    } catch (error) {
      console.error('Formatting error:', error, value)
      return fallbackValue
    }
  }
}