import React, { useState } from 'react'
import { Box, Button, Tooltip } from "@mui/material"
import MicIcon from '@mui/icons-material/Mic'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { Path } from 'routes'
import { config } from 'service/api'
import { useTranslation } from 'react-i18next'

export function OpenWebRecorderButton() {
  const { t } = useTranslation()
  const { isBlocked, isLoading } = useIsBlockedByPlan()

  return (
    <>
      <Tooltip title={isBlocked ? 'Requires Starter or Standard Plan' : ""}>
        <Box>
          <Button variant='contained'
            onClick={() =>
              window.open(`${config.web}${Path.WebRecorder}`, '_blank', 'noopener,noreferrer')
            }
            size='large'
            disabled={isBlocked || isLoading}
            startIcon={<MicIcon />}
            sx={{ width: 200 }}
          >
            {t('Web Recorder')}
          </Button>
        </Box>
      </Tooltip >

    </>
  )
}