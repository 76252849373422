import React, { useRef } from 'react'
import { Box, Button, ButtonBase, Card, Link, SxProps, Tooltip, Typography } from '@mui/material'
import { gColor, gSx } from '../../styles/Theme'
import { Media } from '../../service/media'
import { auth } from 'service/api'
import Grid from '@mui/material/Unstable_Grid2'
import { useQuery } from '@tanstack/react-query'
import { getUsage } from 'service'
import ReactPlayer from 'react-player'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import { useTranslation } from 'react-i18next'

export default function Guide() {
  const { t, i18n } = useTranslation()
  const fbUser = auth.currentUser
  const playerRef = useRef(null)

  const { data: usage, isLoading } = useQuery({
    queryKey: ['getUsage'], queryFn: getUsage,
  })
  const numEvents = usage?.events?.length ?? 0

  if (isLoading || numEvents > 0)
    return null

  return (
    <Card sx={sxCard}>
      {
        fbUser?.displayName
          ? <Typography variant='h3'>{t('Welcome')} {fbUser.displayName}! </Typography>
          : <Typography variant='h3'>{t('Welcome')}!</Typography>
      }

      <Box sx={{ padding: 1 }} />
      <Typography>{t('Welcome Description')}</Typography>

      <Box sx={{ padding: 1 }} />

      <Grid container spacing={2}>

        <Grid xs={12} md={12} lg={6} sx={sxGridCell}>
          <Box sx={{ maxWidth: 500, minHeight: 280 }}>
            <ReactPlayer
              ref={playerRef}
              url={'https://youtu.be/5qOyduKWw9g'}
              controls
              light={true}
              width={`100%`}
              height={`100%`}
              volume={0.5}
            />
          </Box>
        </Grid>

        <Grid xs={12} md={6} lg={3} sx={sxGridCell}>

          <Box sx={gSx.CenterVertical}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={gSx.Row}>
                <Tooltip title='Zoom Meetings and Phone'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.zoomLogo}
                    alt="Zoom"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>Zoom</Typography>
              </Box>

              <Box sx={gSx.Row}>
                <Tooltip title='Google Meet'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.meetLogo}
                    alt="Google Meet"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>Google Meet</Typography>
              </Box>

              <Box sx={gSx.Row}>
                <Tooltip title='Microsoft Teams'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.teamsLogo}
                    alt="Microsoft Teams"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>Microsoft Teams</Typography>
              </Box>

              <Box sx={gSx.Row}>
                <Tooltip title='Webex'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.webexLogo}
                    alt="Webex"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>Webex</Typography>
              </Box>

              <Box sx={gSx.Row}>
                <Tooltip title='Webex'>
                  <Box
                    component="img"
                    sx={sxIcon}
                    src={Media.ringCentralLogo}
                    alt="RingCentral"
                  />
                </Tooltip>
                <Typography sx={sxVideoText}>RingCentral</Typography>
              </Box>

            </Box>
          </Box>
        </Grid>

        <Grid xs={12} md={6} lg={3} sx={sxGridCell}>

          <Box sx={gSx.CenterVertical}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                sx={{ marginY: 1 }}
                startIcon={<LocalPhoneIcon />}
                onClick={() => window.open("https://docs.finmate.ai/docs/category/phone-calls", "_blank")}
              >{t('Phone Guide')}
              </Button>

              <ButtonBase
                href='https://apps.apple.com/us/app/finmate-ai/id6469643088'
                target="_blank"
                rel="noopener"
                sx={{ justifyContent: 'flex-start' }}
              >
                <Box
                  component="img"
                  sx={{ width: 135, height: 43 }}
                  src={Media.appleAppStore}
                />
              </ButtonBase>

              <Box sx={{ padding: 0.5 }} />

              <ButtonBase
                href='https://play.google.com/store/apps/details?id=com.finmate.ai'
                target="_blank"
                rel="noopener"
                sx={{ justifyContent: 'flex-start' }}
              >
                <Box
                  component="img"
                  sx={{ width: 135, height: 43 }}
                  src={Media.googlePlayStore}
                />
              </ButtonBase>
            </Box>
          </Box>
        </Grid>

      </Grid >
    </Card >
  )
}

const sxGridCell: SxProps = {
  display: 'flex',
  flexDirection: 'column',
}

const sxCard: SxProps = {
  display: 'flex',
  flexDirection: 'column',
}

const sxIcon: SxProps = {
  marginLeft: '5px',
  width: '40px'
}

const sxVideoText: SxProps = {
  paddingLeft: 2,
  fontWeight: 'bold'
}