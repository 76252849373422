import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { Pension } from '../../client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { hideTable, foldTable } from './financesUtils'
import TableAccordion from './TableAccordion'
import { getNumberCell } from './CustomCells'

interface Props {
  eventId?: string
  pensionListInput?: Pension[]
  foldEmptyTable: boolean
  hideEmptyTable: boolean
  readOnly: boolean
  onUpdate: (updated: Pension[]) => (void)
}

export default function PensionTable({ eventId, pensionListInput, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: Props) {

  const [expanded, setExpanded] = useState(true)
  const [pensionList, setPensionList] = useState<Pension[]>([])
  const [newPension, setNewPension] = useState<Pension>()

  useEffect(() => {
    if (pensionListInput) {
      console.log("pensionList", pensionListInput)
      setPensionList(pensionListInput)
    }
  }, [pensionListInput])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, pensionList))
  }, [foldEmptyTable, pensionList])

  const columns = useMemo<MRT_ColumnDef<Pension>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewPension((prevPlan) => ({
                ...prevPlan,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...pensionList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewPension((prevPlan) => ({
                ...prevPlan,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...pensionList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Distribution Type',
        header: 'Distribution Type',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewPension((prevPlan) => ({
                ...prevPlan,
                'Distribution Type': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...pensionList]
            updated[rowIdx]['Distribution Type'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Distribution Amount',
        header: 'Distribution Amount',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewPension((prevPlan) => ({
                ...prevPlan,
                'Distribution Amount': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...pensionList]
            updated[rowIdx]['Distribution Amount'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Distribution Year',
        header: 'Distribution Year',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewPension((prevPlan) => ({
                ...prevPlan,
                'Distribution Year': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...pensionList]
            updated[rowIdx]['Distribution Year'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [pensionList, newPension],
  )

  const handleCreate: MRT_TableOptions<Pension>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...pensionList]
    if (newPension) {
      updated.push(newPension)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<Pension>) {
    const updated = [...pensionList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: pensionList,
    editDisplayMode: 'cell',
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: !readOnly,
    enableRowActions: !readOnly,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewPension({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New Pension</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  if (hideTable(hideEmptyTable, pensionList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title="Pensions" table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
