import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { ClientData, EmploymentIncome, Employment } from '../../client'
import { getNumberCell } from './CustomCells'

interface Props {
  eventId?: string
  employmentList?: Employment[]
  readOnly: boolean
  onUpdate: (updated: Employment[]) => (void)
}

export default function EmploymentTable({ eventId, employmentList, readOnly, onUpdate }: Props) {

  const cellRef = useRef(null)
  const [employment, setEmployment] = useState<Employment[]>([])

  useEffect(() => {
    if (employmentList) {
      setEmployment(employmentList)
    }
  }, [employmentList])

  const columns = useMemo<MRT_ColumnDef<Employment>[]>(
    () => [
      {
        accessorKey: 'Employer',
        header: 'Employer',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...employment]
            updated[rowIdx]['Employer'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 140
      },
      {
        accessorKey: 'Title / Job Position',
        header: 'Title / Job Position',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...employment]
            updated[rowIdx]['Title / Job Position'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'No. of Years Working Here',
        header: 'No. of Years Working Here',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...employment]
            updated[rowIdx]['No. of Years Working Here'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Salary & Tips',
        header: 'Salary & Tips',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...employment]
            updated[rowIdx]['Salary & Tips'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Bonus & Commissions',
        header: 'Bonus & Commissions',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...employment]
            updated[rowIdx]['Bonus & Commissions'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
    ],
    [employment],
  )

  const table = useMaterialReactTable({
    columns,
    data: employment,
    editDisplayMode: 'cell',
    enableEditing: !readOnly,
    enableRowActions: false,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
  })

  return (

    <MRT_TableContainer table={table} />

  )
}
