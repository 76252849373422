import React, { useEffect, useState } from 'react'
import { auth, config } from '../../service/api'
import { Box, SxProps, Card, Typography, IconButton, CardHeader, Avatar, Button } from '@mui/material'
import { capitalizeEveryWord } from '../../utils'
import EditIcon from '@mui/icons-material/Edit'
import { gSx } from 'styles/Theme'
import ProfileEditDialog from './ProfileEditDialog'
import ProfilePhoto from './ProfilePhoto'
import ProfilePhotoEditDevOnly from './ProfilePhotoEditDevOnly'
import LanguageSelector from 'components/i18n/LanguageSelector'
import { useTranslation } from 'react-i18next'

export default function ProfileSettingsTab() {
  const fbUser = auth.currentUser
  const { t } = useTranslation()
  const [showDialog, setDialog] = useState(false)

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>
      <CardHeader
        action={
          <Button variant='outlined'
            startIcon={<EditIcon fontSize='small' />}
            onClick={() => setDialog(true)}
          >
            {t('Edit')}
          </Button>
        }
        title={t('Profile')}
      />

      <Box sx={gSx.Row}>
        <ProfilePhoto photoUrl={fbUser?.photoURL} width={60} />
        <Box sx={{ padding: 1 }} />
        <Box>
          <Typography variant='h3'> {capitalizeEveryWord(fbUser?.displayName ?? '')}</Typography>
          <Typography>{fbUser?.email}</Typography>
        </Box>
      </Box>

      <Box sx={gSx.Row}>
        <Box sx={{ padding: 4.5 }} />
        <Typography>{t('Preferred Language')}</Typography>
        <Typography variant='caption' color={'red'} sx={{ paddingBottom: 1.5 }}>{t('Beta')}</Typography>
        <Box sx={{ padding: 1 }} />
        <LanguageSelector />
      </Box>
      {
        showDialog &&
        <ProfileEditDialog
          open={showDialog}
          onClose={() => setDialog(false)}
        />
      }

      {/* <ProfilePhotoEditDevOnly /> */}
    </Card >
  )
}
