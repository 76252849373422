import React, { ReactNode } from 'react'
import { Box, ListItemButton, ListItemIcon, ListItemText, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export default function GuideButton() {
  const { t, i18n } = useTranslation()
  return (
    <Box sx={{ paddingY: '1px' }}>
      <ListItemButton
        onClick={() => window.open('https://docs.finmate.ai', '_blank')}
        sx={sxSideNavListItemButton}
      >
        <ListItemIcon
          sx={{
            minWidth: '38px',
            color: 'lightslategrey',
          }}
        >
          <InfoOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={t('Guide')} />
      </ListItemButton>
    </Box>
  )
}

export const sxSideNavListItemButton: SxProps = {
  '&.Mui-selected': {
    backgroundColor: 'grey.300',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'grey.300',
  },
  ':hover': {
    backgroundColor: 'grey.300',
  },
}
