import { ActionItem, AssigneeInfo, ClientDetail, RedtailBase, RedtailTeam, RedtailUser, RedtailVisibility, RedtailWorkflow, RedtailWorkflowTemplate } from "client"
import { logErr, logInfo } from "log"
import { Api, auth } from "../api"
import { getErrorMessage } from "utils"

export async function getRedtailSettings(): Promise<RedtailBase | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    return await api.redtail.getRedtailSettings()
  } catch (e) {
    logErr('getRedtailSettings', { e })
  }
}

export async function authenticate(user: string, password: string): Promise<Error | undefined> {
  if (!auth.currentUser)
    return new Error("missing auth.currentUser")
  try {
    logInfo('Redtail Authenticate', { user })
    const api = await Api()
    await api.redtail.authenticate({ user, password })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Redtail Authenticate', { err })
    return err
  }
}

export async function getRedtailUsers() {
  try {
    const api = await Api()
    return await api.redtail.getRedtailUsers()
  } catch (e) {
    logErr('getRedtailUsers', { e })
  }
}

export async function getRedtailCalendarDb() {
  try {
    const api = await Api()
    return await api.redtail.getRedtailCalendarDb()
  } catch (e) {
    logErr('getRedtailCalendarDb', { e })
  }
}

export async function syncRedtailCalendar() {
  try {
    const api = await Api()
    return await api.redtail.syncRedtailCalendar()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setRedtailSyncCalendar', { err })
    return err
  }
}

export async function setRedtailUserNotify(user?: RedtailUser) {
  if (!user)
    user = {}
  try {
    const api = await Api()
    return await api.redtail.setUserNotify(user)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setRedtailUserNotify', { err })
    return err
  }
}

export async function getRedtailTeams() {
  try {
    const api = await Api()
    return await api.redtail.getRedtailTeams()
  } catch (e) {
    logErr('getRedtailTeams', { e })
  }
}

export async function setRedtailTeamNotify(team?: RedtailTeam) {
  if (!team)
    team = {}
  try {
    const api = await Api()
    return await api.redtail.setTeamNotify(team)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setRedtailTeamNotify', { err })
    return err
  }
}

export async function setRedtailVisibility(visibility: RedtailVisibility) {
  try {
    const api = await Api()
    return await api.redtail.setRedtailVisibility(visibility)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('redtail set visibility', { err })
    return err
  }
}

export async function importRedtailContacts(): Promise<number | Error> {
  try {
    logInfo('Redtail importContacts')
    const api = await Api()
    return await api.redtail.importRedtailContacts()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('Redtail importContacts', { err })
    return err
  }
}

export async function getRedtailCategories() {
  try {
    const api = await Api()
    return await api.redtail.getRedtailCategories()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getRedtailCategories', { err })
    return err
  }
}

export async function sendRedtailNotes(eventId: string, notes: string, contact?: ClientDetail): Promise<Error | undefined> {

  if (!contact)
    return new Error('missing client')

  try {
    logInfo('sendRedtailNotes', { contact, eventId })
    const api = await Api()
    await api.redtail.sendRedtailNotes({
      event_id: eventId, notes, contact,
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('sendRedtailNotes', { err })
    return err
  }
}

export async function sendRedtailTasks(contact?: ClientDetail, eventId?: string, tasks?: ActionItem[]): Promise<Error | undefined> {

  if (!contact)
    return new Error('missing client')
  if (!eventId)
    return new Error('missing event Id')
  if (!tasks)
    return new Error('missing tasks')

  try {
    logInfo('sendRedtailTasks', { contact, eventId })
    const api = await Api()
    await api.redtail.sendRedtailTasks({
      event_id: eventId, contact, tasks,
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('sendRedtailTasks', { err })
    return err
  }
}

export async function disconnect(): Promise<Error | undefined> {
  try {
    logInfo('Redtail Sign out Pressed')
    const api = await Api()
    await api.redtail.disconnect()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('redtail disconnect', { err })
    return err
  }
}

export async function getRedtailWorkflowTemplates() {
  try {
    const api = await Api()
    return await api.redtail.getWorkflowTemplates()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getRedtailWorkflowTemplates', { err })
    return err
  }
}

export type OwnerType = 'User' | 'Team'

export async function createRedtailWorkflow(event_id: string, wf: RedtailWorkflow) {
  try {
    const api = await Api()
    return await api.redtail.createWorkflow(event_id, wf)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('getRedtailWorkflows', { err })
    return err
  }
}

// used for editting workflows
// export async function getRedtailWorkflows() {
//   try {
//     const api = await Api()
//     return await api.redtail.getWorkflows()
//   } catch (e) {
//     const err = getErrorMessage(e)
//     logErr('getRedtailWorkflows', { err })
//     return err
//   }
// }
