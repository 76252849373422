import { logErr } from "log"
import { Api } from "./api"
import { ClientDetail, ClientIndex } from "client"
import { getErrorMessage, isEmailValid } from "utils"

export async function getClientDetail(client_id?: string) {
  if (!client_id)
    return
  try {
    const api = await Api()
    return await api.clients.getClientDetail(client_id)
  } catch (e) {
    logErr('getClientDetail', { e })
  }
}

export async function getClientsIndex() {
  try {
    const api = await Api()
    return await api.clients.getClientsIndex()
  } catch (e) {
    logErr('getClientsIndex', { e })
  }
}

export function getRecentClients(limit: number, clients?: ClientIndex[]) {
  if (!clients)
    return []

  // sort last_event_datetime descending
  clients.sort((a, b) => {
    const dateA = a.last_event_datetime ? new Date(a.last_event_datetime).getTime() : -Infinity
    const dateB = b.last_event_datetime ? new Date(b.last_event_datetime).getTime() : -Infinity
    if (dateA !== -Infinity && dateB !== -Infinity) {
      return dateB - dateA
    }
    if (dateA !== -Infinity) return -1
    if (dateB !== -Infinity) return 1
    return 0
  })

  return clients.slice(0, limit)
}

export async function setClientEvent(eventId: string, clientId?: string) {
  if (!clientId)
    return
  try {
    const api = await Api()
    return await api.events.setClientEvent(eventId, clientId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('setClientEvent', { err })
    return err
  }
}

export async function upsertClient(client: ClientDetail, eventId?: string) {

  if (client.email && !isEmailValid(client.email ?? ''))
    return new Error('Email is invalid') // updating notes doesnt pass email
  try {
    const api = await Api()
    return await api.clients.upsertClient(client, eventId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('upsertClient', { err })
    return err
  }
}

export async function deleteClient(clientId?: string) {
  if (!clientId)
    return new Error('missing client Id')
  try {
    const api = await Api()
    return await api.clients.deleteClient(clientId)
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('deleteClient', { err })
    return err
  }
}

export function getContactName(contact?: ClientIndex | ClientDetail) {
  return contact?.last_name
    ? `${contact.first_name ?? ''} ${contact.last_name ?? ''}`
    : contact?.email ?? ''
}