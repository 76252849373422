import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, SxProps, Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { saveWealthboxTokens } from 'service/integrations/wealthboxApi'
import { Media } from 'service/media'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import { gSx } from 'styles/Theme'

export default function AuthWealthboxCallback() {
  const url = window.location.href
  const urlParams = new URLSearchParams(new URL(url).search)
  const code = urlParams.get("code") ?? ''
  const queryClient = useQueryClient()
  const [err, setErr] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['saveWealthboxTokens'],
    queryFn: async () => await saveWealthboxTokens(code),
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (data instanceof Error) {
      setErr(data.message)
    } else if (data == true) {
      queryClient.invalidateQueries({ queryKey: ['getWealthboxSettings'] })
    }
  }, [data])

  return (
    <Box sx={gSx.CenterPage}>
      <Box sx={gSx.Row}>
        <Box component='img' sx={sxIcon} src={Media.logo100} />
        <SyncAltIcon />
        <Box component='img' sx={sxIcon} src={Media.wealthboxIcon} alt="Wealthbox" />
      </Box>

      <Box sx={{ padding: 2 }} />

      {isLoading
        ? <>
          <Typography variant='h3'>Finalizing Wealthbox Connections</Typography>
          <Box sx={{ padding: '10px' }} />
          <CircularProgress />
        </>
        : data == true
          ? <>
            <Typography variant='h3'>You are signed into Wealthbox</Typography>
            <Typography variant='h4'>You may close this window</Typography>
          </>
          : <>
            <Typography variant='h3'>Something went wrong</Typography>
            <Typography sx={{ color: 'tomato' }}>{err}</Typography>
          </>
      }
    </Box>
  )
}

const sxIcon: SxProps = {
  height: '50px',
  margin: 3
}
